import React, { useEffect, useState } from 'react';
// import S3 from 'aws-sdk/clients/s3.js';
// import AWS from 'aws-sdk';
import search from '../../assets/icons/search-icon.svg';
import next_path from '../../assets/icons/next-path.svg';
// import back_path from '../../assets/icons/back-path.svg';
// import back_path from '../../assets/icons/back-path-pro.svg';
// import upload_icon from '../../assets/icons/upload.svg';
// import download_icon from '../../assets/icons/download-icon.svg';
// import save_icon from '../../assets/icons/settings/save-icon.svg';
// import delete_icon from '../../assets/icons/delete-btn.svg';
// import Button from '../CustomButton/ButtonContainer';
import { DebounceInput } from 'react-debounce-input';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
// import Folders from './FolderManagement/Folders';
// import Contents from './ContentManagement/Contents';
import { setErrorMessage, setSuccessMessage } from '../../unified/reducer';
// import { Select } from 'antd';
import './ContentComponent.scss';
// import {
//   createFolderContent, createContentFolder, 
//   toggleCreateFolderModal, getOneTimeFolderContents, setOneTimeFolderContents,
//   saveContentDetails,
//   showDeleteMessage, deleteContents, updateFolderContentNewVersion,
//   setSearchFolderText, setSearchContentText,
//   setSortFolderListSortType, setSortContentListSortType
// } from './mediaReducer';
// import FolderForm from './FolderManagement/FolderForm';
// import { convertImageToBase64 } from '../../utils/convertingFunction';
import {
  // downloadContent,
  // download2Content,
  modifyString
} from '../../utils/mediaService';
// import VideoDurationCheck from './ContentManagement/VideoDurationCheck';
// import Search from '../Search/SearchComponent';
import DropdownForm from '../Dropdown/Dropdown';
import ContentLeftBar from '../ContentLeftBar/ContentLeftBar';
import {
  getImage,
  // image_constant
} from '../../unified/image_service';
import ParentContents from './ManageContents/ParentContents';
import InfiniteScroll from 'react-infinite-scroll-component';
// import { Progress } from 'antd';
// import Dialog from '../Dialogs/Dialog';
import {
  setShowingUploadingProgress, setUploadProgressCount, setIsContentUploadedError,
  setFolderPathsToIndexDB, setOpenGlobalModal,
  setFolderPathContentsPayload, setFolderPathContents,
  setShowGlobalWarningDialog, setIsContentDeleted,
  setIsContentComing,
  setViewContentData,
  setIsDataCome,
  setNotFoundData,
} from './reducer';
// import { content_top_dropdown_list } from './content.configs';
import GlobalModal from '../Dialogs/GlobalModal/GlobalModal';
import CreateFolderForm from './ManageContents/CreateFolderForm';
import axios from 'axios';
import { api_url, api_version } from '../../utils/server';
import { generateAccessToken } from '../../profile/reducer';
import { deleteKeyValue, getKeyValue, setKeyValue } from '../../utils/indexedDBManagementService';
import { managingKeyValueData } from './saga';
import ProgressBar from '../Dialogs/ProgressBar/ProgressBar';
import SkeletonLoader from '../Loaders/SkelitonLoader/SkeletonLoader';
import ContentView from './ContentView';
// import Workspace from '../Layout/header/components/workspace';

const ContentComponent = () => {
  const {
    //   default_content_idx,
    //   bio_page_folders,
    //   all_folders,
    //   folderNextDate,
    //   selected_deletable_contents,
    //   folder_contents,
    //   // showing_save_button,
    //   is_showing_create_folder,
    show_uploading_progress,
    uploading_file,
    is_content_uploading_error,
    uploadingProgressCount,
    //   updatable_content,
    //   delete_message,
    //   folder_sort_type,
    //   content_sort_type,
    view_content_data,
    path_contents: content_data,
    contentNextDate, // usable for scrolling
    recentFiles,
    folder_paths,
    is_content_deleted,
    is_content_coming,
    not_found_data,
    is_data_come,
  }: any = useSelector((state: RootState) => state?.contentState);
  const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
  const { setting_teams: teams } = useSelector((state: any) => state?.teamState);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // Extracting the "params" value from the route
  const { params } = useParams<{ params: string }>();
  const route_path = window.location.pathname;
  const category = route_path?.includes("archives")
    ? "archives"
    : route_path?.includes("personal-files")
    ? "personal"
    : route_path?.includes("shared-with-me") // I have to work here...
    ? "sharedWithMe"
    : route_path?.includes("shared-files")
    ? "shared"
    : "main";
  // const query_string = window.location.search;
  // const path = query_string.replace('?', '')?.replace('&', "=");
  // const path_array = path.split("=");
  // const [activeFolder, setActiveFolder] = React.useState<any>(null);
  // ===== starting content functionalities ======
  const [searchText, setSearchText] = React.useState<any>("");
  const [breadCrumbPaths, setBreadCrumbPaths] = useState<any>([]);
  const [path_contents, setPathContents] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(false);

  // uploading time progressing bar
  useEffect(() => {

    if((!show_uploading_progress && uploadingProgressCount) || is_content_uploading_error ) {
      setTimeout(() => {
        dispatch(setUploadProgressCount(0));
        setTimeout(() => {dispatch(setIsContentUploadedError(false))}, 1000);
      }, 4000);
    }

  }, [show_uploading_progress, uploadingProgressCount]);

  // all contents
  useEffect(() => {
    // console.log({ folder_paths, params });
    if (content_data?.length) {
      setPathContents(content_data);
      setTimeout(() => dispatch(setIsContentDeleted(false)), 2000);
      setTimeout(() => setIsLoading(false), 1000);
      dispatch(setIsContentComing(false));
    } else {
      setPathContents([]);
      setTimeout(() => setIsLoading(false), 2000);
      dispatch(setIsContentComing(false));
    }
  }, [content_data?.length]);
  
  useEffect(() => { 
    if (is_content_deleted) {
      if (!content_data?.length) {
        dispatch(setNotFoundData(true));
        setTimeout(() => dispatch(setIsContentDeleted(false)), 2000);
      }
    }
  }, [is_content_deleted]);
  
  useEffect(() => {
    // console.log({ is_content_coming });
    if (is_content_coming) {
      if (is_content_coming === "from-api") {
        setIsLoading(true);
        dispatch(setIsContentComing(false));
        setTimeout(() => setIsLoading(false), 3000);
      }
      dispatch(setIsContentComing(false));
    }
  }, [is_content_coming]);

  // bread-crumbs
  useEffect(() => {
    // console.log({ folder_paths, params });
    if (folder_paths?.length) {
      setSearchText("");
      setPathContents([]);
      setBreadCrumbPaths(folder_paths);
    } else {
      setSearchText("");
      setPathContents([]);
      setBreadCrumbPaths([]);
    }
  }, [route_path, folder_paths?.length]);

  // changing folder routes 
  const handleManageCurrentFolderContent = (data: any) => {
    
    const findCurrentFolderIndex = folder_paths?.findIndex((item: any) => item?.id === data?.id);
    if ((findCurrentFolderIndex + 1) === folder_paths?.length) {
      return;
    }
    const current_folder_path_data = folder_paths?.slice(0, (findCurrentFolderIndex+1));
    const folder_object = {
      isForInserting: true,
      isNotRefreshing: true,
      key: "folder_paths",
      value: current_folder_path_data
    };
    // console.log({ findCurrentFolderIndex, current_folder_path_data, folder_object });
    dispatch(setFolderPathsToIndexDB(folder_object));
  }
  // ===== starting content functionalities ======
  // Get all path contents
  const get_content_payload: any = {
    teamId: "", // if team is come in root otherwise blank ""
    createdOn: new Date().toISOString().replace('Z', ''), // "2024-12-05T05:48:33.831Z",
    category: category,
  };

  const [hasMore, setHasMore] = React.useState<boolean>(true);
  // Scrolling event fetch data
  const fetchMoreData = () => {
    // check team_id is available or not by teamName from params
    if (category === "main") {
      const check_team_available = teams?.find((item: any) => item?.internalName === params || item?.teamName === params || item?.teamId === params);
      if (!check_team_available) {
        navigate("/contents/shared-files");
        return;
      }
      get_content_payload["teamId"] = check_team_available?.teamId;
      get_content_payload["teamInternalName"] = check_team_available?.internalName;
    }
    if (!contentNextDate) {
        setHasMore(false);
        return;
    }
    
    const workspaceId = logged_in_user_secret_data?.wuid;
    const clapupUserId = logged_in_user_secret_data?.cuid;
    if (!workspaceId || !clapupUserId) {
      return;
    }
    const folders = breadCrumbPaths?.slice(1)?.map((item: any) => item.folderPath);
    const folderPath = folders?.join("/");

    get_content_payload["workspaceId"] = workspaceId;
    get_content_payload["clapupUserId"] = clapupUserId;
    get_content_payload["isFolder"] = folderPath ? 1 : 0;
    get_content_payload["createdOn"] = contentNextDate;
    get_content_payload["folderPath"] = folderPath || "";
    setHasMore(true);
    setTimeout(() => {
      // // setContents(contents.concat(contents));
      const contentPayload: any = {
        ...get_content_payload,
        isForScrolling: true,
      };
      // console.log({ contentPayload });
      if (!contentNextDate?.length || !contentNextDate) {
        return;
      }
      // // dispatch(getFolderContents(contentPayload));
      dispatch(setFolderPathContentsPayload(contentPayload));  
    }, 1500);
  };

  const getFolderContents = async (isForNew?: any) => { 
    if (breadCrumbPaths?.length) {
      if (category === "main") {
        if (!teams?.length) {
          return;
        }
        const check_team_available = teams?.find((item: any) => item?.internalName === params || item?.teamName === params || item?.teamId === params);
        // console.log({ params, check_team_available });
        if (!check_team_available) {
          navigate("/contents/shared-files");
          return;
        }
        get_content_payload["teamId"] = check_team_available?.teamId;
        get_content_payload["teamInternalName"] = check_team_available?.internalName;
      }

      const workspaceId = logged_in_user_secret_data?.wuid;
      const clapupUserId = logged_in_user_secret_data?.cuid;
      if (!workspaceId || !clapupUserId) {
        return;
      }
      const folders = breadCrumbPaths?.slice(1)?.map((item: any) => item.folderPath);
      const folderPath = folders?.join("/");
  
      get_content_payload["workspaceId"] = workspaceId;
      get_content_payload["clapupUserId"] = clapupUserId;
      get_content_payload["isFolder"] = 0;
      // get_content_payload["isFolder"] = folderPath ? 1 : 0;
      get_content_payload["folderPath"] = folderPath || "";
      if (isForNew) {
        get_content_payload["createdOn"] = new Date().toISOString().replace('Z', '');
      }
      get_content_payload["isForNew"] = isForNew ? true : false; // if new then api will be called
      
      dispatch(setFolderPathContentsPayload(get_content_payload));
    }
  };

  useEffect(() => {
    (async () => {
      // console.log({ breadCrumbPaths });
      if (breadCrumbPaths?.length) {
        getFolderContents();
      }
    })();
  }, [breadCrumbPaths?.length]);
  
  useEffect(() => {
    (async () => {
      // console.log({ breadCrumbPaths });
      if (breadCrumbPaths?.length && teams?.length) {
        if (category === "main") {
          getFolderContents();
        }
      }
    })();
  }, [teams?.length, breadCrumbPaths?.length]);

  // for managing archive data from indexDB
  const manageArchivesDataFromIndexDB = async (data: any) => {
    const all_path_contents = await getKeyValue("all_path_contents");
    // const key_contents: any = all_path_contents?.find((item: any) => item.hasOwnProperty("archives")) || null;
    const others_stored_contents: any[] = all_path_contents?.filter((item: any) => !item.hasOwnProperty("archives")) || [];
    
    // newContent && dispatch(setFolderPathContents(updated_content_data));
    
    // const current_archives_data = await getKeyValue("archives");
    const current_archives_data = all_path_contents?.find((item: any) => item.hasOwnProperty("archives")) || null;
    if (current_archives_data) {
      const {
        contentDetails, nextDate,
        // recentFiles
      } = current_archives_data["archives"] || {};
      const folder_contents: any[] = contentDetails?.filter((item: any) => item?.isFolder) || [];
      const files: any[] = contentDetails?.filter((item: any) => !item?.isFolder) || [];
      // console.log({ folder_contents, current_archives_data, folder_key_name: "archives" });
      // return;
      const current_folder_path_contents: any[] =  data?.isFolder
                                            ? (contentDetails?.length ? [data, ...contentDetails] : [data])
                                            : [...folder_contents, data, ...files];
                                            // : data && !data?.isFolder 
                                            // ? [...folder_contents, data, ...files]
                                            // : (contentDetails?.length ? [...contentDetails] : []);
      
      const recent_file_content_key = "archives-recent-files";
      const all_path_recent_file_contents = await getKeyValue("all_path_recent_file_contents");
      const others_stored_recent_file_contents: any[] = all_path_recent_file_contents?.filter((item: any) => !item.hasOwnProperty(recent_file_content_key)) || [];
      const current_archive_recent_files: any = all_path_recent_file_contents?.find((item: any) => item.hasOwnProperty(recent_file_content_key)) || null;
      let updated_recent_file_content: any[] = [];
      if (current_archive_recent_files) {
        const archive_recent_files = current_archive_recent_files[recent_file_content_key];
        updated_recent_file_content = archive_recent_files?.length ? [data, ...archive_recent_files] : [data];
      }
      const all_updated_recent_file_contents: any[] = [
        ...others_stored_recent_file_contents,
        {
          [recent_file_content_key]: updated_recent_file_content?.slice(0, 6)
        }
      ];
      console.log({ current_folder_path_contents, all_path_recent_file_contents, current_archive_recent_files, all_updated_recent_file_contents });
      // return;
      await managingKeyValueData({
        // <isForInserting: false>, this is for testing...
        // when it is<isForInserting: true>, api will never called and take data from indexedDB
        isForInserting: true,
        key: "all_path_recent_file_contents",
        value: all_updated_recent_file_contents
      });
      const updated_content_data: any = {
        contentDetails: current_folder_path_contents,
        nextDate: nextDate,
        // recentFiles: recentFiles
      }
  
      // if (data && !data?.isFolder) {
      //   updated_content_data["recentFiles"] = recentFiles?.length ? [data, ...recentFiles?.slice(0, 5)] : [data];
      // }
      // return;
      const all_updated_stored_contents = [
        ...others_stored_contents,
        {
          ["archives"]: updated_content_data
        }
      ];
  
      await managingKeyValueData({
        // <isForInserting: false>, this is for testing...
        // when it is<isForInserting: true>, api will never called and take data from indexedDB
        isForInserting: true,
        key: "all_path_contents",
        value: all_updated_stored_contents
      });
  
      // await managingKeyValueData({
      //   // <isForInserting: false>, this is for testing...
      //   // when it is<isForInserting: true>, api will never called and take data from indexedDB
      //   isForInserting: false,
      //   key: "archives",
      //   value: [
      //     data,
      //     current_archives_data
      //   ]
      // });
    }
    dispatch(setShowGlobalWarningDialog(null));
  }

  // for updatable data
  const updateContentIntoStateAndDB = async (data?: any) => {
    const { updatable_content: updatableContent, success_message, key, value } = data || {};
    const folders = breadCrumbPaths?.slice(1)?.map((item: any) => item?.folderPath);
    const folderPath = folders?.join("/"); // used for key of indexDB

    // get_content_payload["workspaceId"] = workspaceId;
    // get_content_payload["clapupUserId"] = clapupUserId;

    // get_content_payload["isFolder"] = (folderPath || newContent?.isFolder) ? 1 : 0; // when folder-path always 1 else 0
    // get_content_payload["folderPath"] = (folderPath || newContent?.isFolder) ? folderPath : "";
    const { category: root } = get_content_payload;
    let recent_content_files_root: any = root;
    let folder_key_name = `${root}${folderPath ? `-${folderPath}` : ""}`;
    if (root === "main") {
      if (!teams?.length) {
        return;
      }
      const check_team_available = teams?.find((item: any) => item?.internalName === params || item?.teamName === params || item?.teamId === params);
      // console.log({ params, check_team_available });
      if (!check_team_available) {
        navigate("/contents/shared-files");
        return;
      }
      recent_content_files_root = check_team_available?.internalName || check_team_available?.internalFileName;
      folder_key_name = `${recent_content_files_root}${folderPath ? `-${folderPath}` : ""}`;
    }
    // console.log({ updatableContent, folder_key_name });

    if (!folder_key_name) {
      return;
    }

    // const folder_contents: any[] = path_contents?.filter((item: any) => item?.isFolder);
    // const files: any[] = path_contents?.filter((item: any) => !item?.isFolder);
    let updatable_current_folder_path_contents: any[] = path_contents?.map((item: any) => {
      if (item?.contentId === updatableContent?.contentId) {
        return {
          ...item,
          ...updatableContent
        }
      }
      return item;
    }); // path_contents
    
    let updatable_recent_files: any[] = recentFiles?.map((item: any) => {
      if (item?.contentId === updatableContent?.contentId) {
        if (key === "delete") {
          return {
            ...item,
            ...updatableContent,
            isDeleted: true
          }
        }
        if (key === "stage" && value === "archive") {
          return {
            ...item,
            ...updatableContent,
            isArchived: true
          }
        }
        return {
          ...item,
          ...updatableContent
        }
      }
      return item;
    });

    // archive and delete
    if ((key === "stage" && value === "archive") || key === "delete") {
      updatable_current_folder_path_contents = path_contents?.filter((item: any) => item?.contentId !== updatableContent?.contentId);
      // // if (key === "delete") {
      // //   updatable_recent_files = recentFiles?.filter((item: any) => item?.contentId !== updatableContent?.contentId);
      // // } else {
      // //   updatable_recent_files = recentFiles?.filter((item: any) => item?.contentId !== updatableContent?.contentId);
      // // }

      // // should be worked for testing
      // console.log({ updatable_current_folder_path_contents, value });
      // value === "archive" && manageArchivesDataFromIndexDB(updatableContent);
    }

    // return;
    const recent_file_content_key = recent_content_files_root + "-recent-files";
    const all_path_recent_file_contents = await getKeyValue("all_path_recent_file_contents");
    const others_stored_recent_file_contents: any[] = all_path_recent_file_contents?.filter((item: any) => !item.hasOwnProperty(recent_file_content_key)) || [];
    const updated_recent_file_content: any = updatable_recent_files?.length ? updatable_recent_files : [];

    const all_updated_recent_file_contents: any[] = [
      ...others_stored_recent_file_contents,
      {
        [recent_file_content_key]: updated_recent_file_content
      }
    ];
    await managingKeyValueData({
      // <isForInserting: false>, this is for testing...
      // when it is<isForInserting: true>, api will never called and take data from indexedDB
      isForInserting: true,
      key: "all_path_recent_file_contents",
      value: all_updated_recent_file_contents
    });


    const updated_content_data: any = {
      contentDetails: updatable_current_folder_path_contents,
      nextDate: contentNextDate,
      // recentFiles: updatable_recent_files
    }

    console.log({ updatableContent, updated_content_data, folder_key_name });

    const all_path_contents = await getKeyValue("all_path_contents");
    // const key_contents: any = all_path_contents?.find((item: any) => item.hasOwnProperty(folder_key_name)) || null;
    const others_stored_contents: any[] = all_path_contents?.filter((item: any) => !item.hasOwnProperty(folder_key_name)) || [];
    const all_updated_stored_contents = [
      ...others_stored_contents,
      {
        [folder_key_name]: updated_content_data
      }
    ];

    // console.log({ others_stored_contents, all_updated_stored_contents, folder_key_name });

    await managingKeyValueData({
      // <isForInserting: false>, this is for testing...
      // when it is<isForInserting: true>, api will never called and take data from indexedDB
      isForInserting: true,
      key: "all_path_contents",
      value: all_updated_stored_contents
    });

    updatableContent && dispatch(setFolderPathContents(updated_content_data));
    setPathContents(updatable_current_folder_path_contents);
    if ((key === "stage" && value === "archive") || key === "delete") {
      dispatch(setIsContentDeleted(true));
      value === "archive" && manageArchivesDataFromIndexDB(updatableContent);
    }
    setIsLoading(false);
    dispatch(setShowGlobalWarningDialog(null));
    dispatch(setOpenGlobalModal(null));
    
    if (success_message) {
      dispatch(setSuccessMessage(success_message));
    }
  } 

  // for new and previous data
  const concatFolderContent = async (newContent?: any) => {
    const folders = breadCrumbPaths?.slice(1)?.map((item: any) => item.folderPath);
    const folderPath = folders?.join("/");

    // get_content_payload["workspaceId"] = workspaceId;
    // get_content_payload["clapupUserId"] = clapupUserId;

    // get_content_payload["isFolder"] = (folderPath || newContent?.isFolder) ? 1 : 0; // when folder-path always 1 else 0
    // get_content_payload["folderPath"] = (folderPath || newContent?.isFolder) ? folderPath : "";
    const { category: root } = get_content_payload;
    // taking root for recentFiles
    let recent_content_files_root: any = root;
    let folder_key_name = "";
    if (root === "main") {
      if (!teams?.length) {
        return;
      }
      const check_team_available = teams?.find((item: any) => item?.internalName === params || item?.teamName === params || item?.teamId === params);
      // console.log({ params, check_team_available });
      if (!check_team_available) {
        navigate("/contents/shared-files");
        return;
      }
      recent_content_files_root = check_team_available?.internalName || check_team_available?.internalFileName;
      folder_key_name = `${recent_content_files_root}${folderPath ? `-${folderPath}` : ""}`;
    } else {
      folder_key_name = `${recent_content_files_root}${folderPath ? `-${folderPath}` : ""}`;
    }

    // console.log({ newContent, folder_key_name });

    if (!folder_key_name) {
      return;
    }

    const folder_contents: any[] = content_data?.filter((item: any) => item?.isFolder);
    const files: any[] = content_data?.filter((item: any) => !item?.isFolder);
    const current_folder_path_contents: any[] =  newContent?.isFolder
                                          ? (content_data?.length ? [newContent, ...content_data] : [newContent])
                                          : newContent && !newContent?.isFolder 
                                          ? [...folder_contents, newContent, ...files]
                                          : (content_data?.length ? [...content_data] : []);
    
    const updated_content_data: any = {
      contentDetails: current_folder_path_contents,
      nextDate: contentNextDate,
      // recentFiles: recentFiles
    }

    const recent_file_content_key = recent_content_files_root + "-recent-files";
    // const all_path_recent_file_contents = await getKeyValue("all_path_recent_file_contents");
    // const others_stored_recent_file_contents: any[] = all_path_recent_file_contents?.filter((item: any) => !item.hasOwnProperty(recent_file_content_key)) || [];
    const updated_recent_file_content: any = recentFiles?.length ? recentFiles : [];
    if (newContent && !newContent?.isFolder) {
      updated_recent_file_content[recent_file_content_key] = recentFiles?.length ? [newContent, ...recentFiles?.slice(0, 5)] : [newContent];
    }
    // const all_updated_recent_file_contents: any[] = [
    //   ...others_stored_recent_file_contents,
    //   {
    //     [recent_file_content_key]: updated_recent_file_content
    //   }
    // ];
    // await managingKeyValueData({
    //   // <isForInserting: false>, this is for testing...
    //   // when it is<isForInserting: true>, api will never called and take data from indexedDB
    //   isForInserting: true,
    //   key: "all_path_recent_file_contents",
    //   value: all_updated_recent_file_contents
    // });

    // const all_path_contents = await getKeyValue("all_path_contents");
    // // const key_contents: any = all_path_contents?.find((item: any) => item.hasOwnProperty(folder_key_name)) || null;
    // const others_stored_contents: any[] = all_path_contents?.filter((item: any) => !item.hasOwnProperty(folder_key_name)) || [];
    // const all_updated_stored_contents = [
    //   ...others_stored_contents,
    //   {
    //     [folder_key_name]: updated_content_data
    //   }
    // ];

    // console.log({is_data_come, folder_contents, all_path_contents, current_folder_path_contents, all_updated_stored_contents, updated_content_data, folder_key_name });
    
    // if (current_folder_path_contents?.length || is_data_come) {
    if (is_data_come) {
      // await managingKeyValueData({
      //   // <isForInserting: false>, this is for testing...
      //   // when it is<isForInserting: true>, api will never called and take data from indexedDB
      //   isForInserting: true,
      //   key: "all_path_contents",
      //   value: all_updated_stored_contents
      // });
      dispatch(setIsDataCome(false));
    }
    newContent && dispatch(setFolderPathContents(updated_content_data));
    // // await deleteKeyValue(folder_key_name);
  } 
  
  useEffect(() => {
    (async () => {
      if (is_data_come) {
        concatFolderContent();
      }
    })();
  // }, [path_contents?.length, is_data_come]);
  }, [ is_data_come]);
  
  // create folder and file content
  const handleCreateContent = async (content_data: any) => {
    const { file, generate_response_data, contentPayload } = content_data || {};
    const { contentId, internalFileName, internalName, url: pre_assigned_url } = generate_response_data || {};
    const { folderName, isForFolder } = contentPayload || {};
    if (isForFolder) {
      const duplicate = path_contents?.find((item: any) => item?.contentFileName?.toLowerCase() === folderName?.toLowerCase()?.trim());
      if (duplicate) {
        dispatch(setErrorMessage("This folder is already exist"));
        return;
      }
    }
    // const uploading_time = Date.now().toString(); // Unique identifier for this email
    try {
      const workspaceId = logged_in_user_secret_data?.wuid;
      const clapupUserId = logged_in_user_secret_data?.cuid;
      if (!workspaceId || !clapupUserId) {
        return;
      }
      
      // console.log({ content_data });
      const { name: fileName, size, type } = file || {};
      const sizeInBytes = size || 0;
      // Convert bytes to kilobytes (KB)
      const sizeInKB = sizeInBytes / 1024;
      // // Convert bytes to megabytes (MB)
      // const sizeInMB = sizeInKB / 1024;
      let fileSize = "";
      // if (sizeInMB > 15) {
      //   dispatch(setShowingUploadingProgress(false));
      //   dispatch(setIsContentUploadedError(true));
      //   dispatch(setErrorMessage(`Error. Maximum file size is 15 MB`));
      //   return;
      // }
      fileSize = sizeInKB.toFixed(2) + " KB";
      // if (sizeInMB > 1) {
      //   fileSize = sizeInMB.toFixed(2) + " MB";
      // } else {
      //   fileSize = sizeInKB.toFixed(2) + " KB";
      // }

      const folders = breadCrumbPaths?.slice(1)?.map((item: any) => item.folderPath);
      const folderPath = folders?.join("/");
      let file_type = type;
      if (!isForFolder) {
        const content_name = modifyString(fileName, 1, 6, ".");
        if (content_name?.includes(".sfdt")) {
          file_type = "application/json";
        }
      }
      const contentObject: any = {
        workspaceId: workspaceId,
        clapupUserId: clapupUserId,
        contentId: contentId || "",
        internalFileName: folderName?.replaceAll(" ", "")?.toLowerCase() || internalFileName || internalName || "",
        contentFileName: folderName || fileName || "",
        contentFileType: !isForFolder ? file_type : "",
        // it may be kB or MB otherwise what we have bytes value
        fileSize: !isForFolder ? fileSize : "",
        category: category,
        teamId: "", // find team by teamName ===> if root path is team then that teamId otherwise empty
        teamFolderName: "", // what will be teamFolderName value ===> when root is team
        isFolder: isForFolder ? 1 : 0, // what will be isFolder value ====> when file 0 otherwise 1(folder-creating)
        folderPath: folderPath || "", // what will be folderPath value ====> if root > F1 > F2 user F1/F2...like this otherwise empty
        cloudFlareR2Path: "", // what will be cloudFlareR2Path value ====> now blank
      };

      // console.log({ params, teams });
      // return;
      if (category === "main") {
        if (!teams?.length) {
          return;
        }
        const check_team_available = teams?.find((item: any) => item?.internalName === params || item?.teamName === params || item?.teamId === params);
        console.log({ params, check_team_available, teams });
        if (!check_team_available) {
          if (!isForFolder) {
            // error test
            dispatch(setUploadProgressCount(100));
            dispatch(setIsContentUploadedError(true));
            setTimeout(() => { 
              dispatch(setShowingUploadingProgress(false));
              dispatch(setUploadProgressCount(0));
            }, 3000);
            // setTimeout(() => { 
            //   dispatch(setShowingUploadingProgress(false));
            //   dispatch(setIsContentUploadedError(true));
            // }, 2000);
          }
          navigate("/contents/shared-files");
          return;
        }
        contentObject["teamId"] = check_team_available?.teamId;
        contentObject["teamFolderName"] = check_team_available?.internalFileName || check_team_available?.internalName;
      }

      // console.log({contentObject, folders, folderPath, breadCrumbPaths});
      
      // // Creating content
      const { data, status } = await axios.post(`${api_url}/api/${api_version}/content/CreateContent`, contentObject, {
        headers: {
          'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
          'Content-Type': 'application/json'
        }
      });
    
      if (status === 200) {
        console.log("Content response: ", { data });
        // setTimeout(() => getFolderContents(true), 2000); // for new fetch
        concatFolderContent(data);
        // getFolderContents();
        if (isForFolder) {
          dispatch(setOpenGlobalModal(null));
          // dispatch(setUploadProgressCount(0));
          dispatch(setSuccessMessage("Folder Created Successfully"));
        } else {
          // success test
          dispatch(setUploadProgressCount(100));
          setTimeout(() => { 
            dispatch(setShowingUploadingProgress(false));
          }, 5000);
          // dispatch(setUploadProgressCount(100));
          // setTimeout(() => { 
          //   dispatch(setShowingUploadingProgress(false, null));
          // }, 3000);
          // dispatch(setSuccessMessage("File Uploaded Successfully"));
        }
      }
      // return response.data; // Return response if needed
      
    } catch (error) {
      const { response }: any = error;
      console.log('error', response);
      const { status } = response || {};
      if (!isForFolder) {
        // error test
        dispatch(setUploadProgressCount(100));
        dispatch(setIsContentUploadedError(true));
        setTimeout(() => { 
          dispatch(setShowingUploadingProgress(false));
          dispatch(setUploadProgressCount(0));
        }, 3000);
      }
      if (status === 403 || status === 401) {
          dispatch(generateAccessToken());
      }
      // else {
      //   dispatch(setErrorMessage("Something went wrong"));
      // }
    }
  };

  // upload file
  const handleUploadContent = async ( file: any, content?: any ) => {
    // const uploading_time = Date.now().toString(); // Unique identifier for this email
    try {
      const { name: fileName, type } = file;
      console.log({ fileName, fileType: type });
      // return;
      const duplicateCheck = path_contents?.find((item: any) => item?.contentFileName?.toLowerCase() === fileName?.toLowerCase());
      if (duplicateCheck) {
        dispatch(setErrorMessage("This content is already exist"));
        return;
      }
      const workspaceId = logged_in_user_secret_data?.wuid;
      const clapupUserId = logged_in_user_secret_data?.cuid;
      if (!workspaceId || !clapupUserId) {
        return;
      }

      // const sizeInBytes = size;
      // // Convert bytes to kilobytes (KB)
      // const sizeInKB = sizeInBytes / 1024;
      // // Convert bytes to megabytes (MB)
      // const sizeInMB = sizeInKB / 1024;
      // let fileSize = "";
      // // if (sizeInMB > 15) {
      // //   dispatch(setShowingUploadingProgress(false));
      // //   dispatch(setIsContentUploadedError(true));
      // //   dispatch(setErrorMessage(`Error. Maximum file size is 15 MB`));
      // //   return;
      // // }
      // if (sizeInMB > 1) {
      //   fileSize = sizeInMB.toFixed(2) + " MB";
      // } else {
      //   fileSize = sizeInKB.toFixed(2) + " KB";
      // }

      // const root_folder_category = folder_paths?.length ? folder_paths[0] : {};
      const folders = folder_paths?.slice(1)?.map((item: any) => item.folderPath);
      const folderPath = folders?.join("/");

      const contentObject: any = {
        workspaceId: workspaceId,
        clapupUserId: clapupUserId,
        teamInternalName: "",
        contentId: content?.contentId || "", // if we want to upload new version then contentId should be there
        fileName: fileName,
        path: folderPath || "", // "root -> blank or parent-folder-1/parent-folder-2 ",
        contentFileType: type,
        mode: content?.contentId ? "update" : "new", // "new or update",
        category: category, // "shared or personal or main(if from team)",
        version: 0, //if file is new 0 else 1
      };

      if (category === "main") {
        if (!teams?.length) {
          return;
        }
        const check_team_available = teams?.find((item: any) => item?.internalName === params || item?.teamName === params || item?.teamId === params);
        // console.log({ params, check_team_available, teams });
        if (!check_team_available) {
          navigate("/contents/shared-files");
          return;
        }
        // contentObject["teamId"] = check_team_available?.teamId;
        contentObject["teamInternalName"] = check_team_available?.internalFileName || check_team_available?.internalName;
      }

      // console.log({contentObject, root_folder_category});
      // return;
      
      // // uploading started
      dispatch(setShowingUploadingProgress(true, file));
      dispatch(setUploadProgressCount(1));
      // Uploading file for getting contentId, pre_assigned_url, internalFileName
      // const content_name = modifyString(fileName, 1, 6, ".");
      // if (!type && !content_name?.includes(".sfdt")) {
      //   throw ("Format not supported");
      // }
      const { data: generated_data, status: generated_status } = await axios.post(`${api_url}/api/${api_version}/content/GeneratePutPresignedUrl`, contentObject, {
        headers: {
            'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
            'Content-Type': 'application/json'
        }
      });
      
      // console.log("File response: ", { generated_data });
      // return;
      if (generated_status === 200 && generated_data?.url) {
        const pre_assigned_url = generated_data?.url;
        // const pre_assigned_url = "https://dc474190-f363-4e8e-9949-8cdac65b4d44.7e93201b14c845ca2c0a69a67557e543.r2.cloudflarestorage.com/sharedfiles/mmhk25-39ac0237-bdfd-41d3-9c2b-654bd4b77522-1.png?X-Amz-Expires=3577&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=0ad77b3401369b73425d76c732dcf41e%2F20241207%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241207T053810Z&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Signature=6c70609da1df07783bc977b64a6ef3a6aaf1ccd08e823515c8936dc00a7a8856";
        const { status } = await axios.put(pre_assigned_url, file, {
        // const { data, status } = await axios.put(generated_data?.url, file, {
          headers: {
            'Content-Type': `${type}`, // Set the Content-Type based on the file type
          }
        });
        // console.log({ generated_data,  data});
        if (status === 200) {
          handleCreateContent({ file, generate_response_data: generated_data, contentPayload: contentObject });
        }
      }
      // testing purpose
      // handleCreateContent({file, generate_response_data: {contentId: "10255", internalFileName: "testing", url: "https://google.com"}, contentPayload: contentObject});
      // return response.data; // Return response if needed
      
    } catch (error) {
      const { response }: any = error;
      console.log('error', {error, response});
      const { status } = response || {};
      // error test
      let error_message = error === "Format not supported" ? error : true;
      dispatch(setUploadProgressCount(100));
      dispatch(setIsContentUploadedError(error_message));
      setTimeout(() => { 
        dispatch(setShowingUploadingProgress(false));
        dispatch(setUploadProgressCount(0));
      }, 3000);
      // setTimeout(() => { 
      //   dispatch(setShowingUploadingProgress(false));
      //   dispatch(setIsContentUploadedError(true));
      // }, 2000);
      if (status === 403 || status === 401) {
          dispatch(generateAccessToken());
      }
      // else {
      //     dispatch(setErrorMessage("Something went wrong"));
      // }
    }
  };

  useEffect(() => {
    if (content_data?.length && searchText) {
      handleSearch(searchText);
    } else if (content_data?.length) {
      setPathContents(content_data);
      setIsLoading(false);
    }
  }, [searchText]);

  // search folder and contents
  const handleSearch = (searched_text: any) => {
    // console.log({ searched_text, content_data });
    const filter_searched_data = content_data?.filter((item: any) => {
      return (
        item?.contentFileName?.toLowerCase().includes(searched_text?.toLowerCase())
        || 
        item?.createdByName?.toLowerCase().includes(searched_text?.toLowerCase())
        || 
        item?.createdOn?.toLowerCase().includes(searched_text?.toLowerCase())
      );
    });
    setPathContents(filter_searched_data);
    setIsLoading(false);
  };
  // ==== new requirements ========
  const [modalType, setModalType] = useState<any>(null);
  const [isOpenAddNewButton, setOpenAddNewDropdown] = useState<any>(null);

  const menu = (
    <div className='dropdown-container'>
      <div className='dropdown-item'
        onClick={() => {
          dispatch(setOpenGlobalModal("create-folder"));
          setOpenAddNewDropdown(false);
          setModalType("create-folder");
        }}
      >
        <img src={getImage("circle-plus")} alt="content-icon" />
        <span>Create Folder</span>
      </div>
      {/* folder input */}
      <label className='dropdown-item'
        htmlFor="folder-input"
        onClick={() => {
          setOpenAddNewDropdown(false);
          setModalType("upload-folder");
        }}
        >
        <img src={getImage("circle-top-arrow")} alt="content-icon" />
        <span>Upload Folder</span>
      </label>
      <input
        id={"folder-input"} type="folder"
        accept={"*"}
        onChange={async (e: any) => {
          // console.log({ img: e?.target?.files[0] });
          // dispatch(setShowingUploadingProgress(true));
          // dispatch(setUploadProgressCount(1));
          try {
            const file = e.target.files[0];
            // const { type } = file;
            // handleUploadNewVersionFile(file);
            // handleCreateContent(file);
            
            // setTim
          } catch (err: any) {
            console.log({ err });
            dispatch(setErrorMessage("Something went wrong"));
          }

        }}
        className="d-none"
      />
      {/* folder input */}

      {/* file input */}
      <label className='dropdown-item'
        htmlFor='file-input'
        onClick={() => {
          setOpenAddNewDropdown(false);
          setModalType("upload-file");
        }}
      >
        <img src={getImage("circle-top-arrow")} alt="content-icon" />
        <span>Upload File</span>
      </label>
      <input
        id={"file-input"} type="file"
        accept={"*"}
        onChange={async (e: any) => {
          try {
            const file: any = e.target.files[0];
            // const { type, size } = file;
            // console.log({ file });
            if (!file) {
              return
            }
            handleUploadContent(file);
          } catch (err: any) {
            console.log({ err });
            // dispatch(setErrorMessage("Something went wrong"));
          }
          
        }}
        className="d-none"
      />
        {/* file input */}
        {/* <ul>
            {
              content_top_dropdown_list?.map((item: any, idx: number) => (
                <li
                  key={idx}
                  onClick={() => {
                    setOpenAddNewDropdown(false);
                    setModalType(item);
                  }}
                >
                  <img src={getImage(item?.icon_title)} alt="circle-icon" />
                  <div>{item?.title}</div>
                </li>
              ))
            }
        </ul> */}
    </div>
  );

  const getContentIconTitle = (content: any) => {
    if (content?.isFolder) {
      return "folder-icon";
    }
    const content_name = modifyString(content?.contentFileName, 1, 6, ".");
    if (
      content?.contentFileType?.includes("image")
      && content_name?.toLowerCase()?.includes(".svg")
    ) {
      return "content-svg-icon";
    }
    else if (content?.contentFileType?.includes("image")) {
      return "content-image-icon";
    }
    else if (content?.contentFileType?.includes("audio")) {
      return "content-audio-icon";
    } 
    else if (content?.contentFileType?.includes("video")) {
      return "content-video-icon";
    }
    else if (content_name?.toLowerCase()?.includes(".xml")) {
      return "content-xml-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".less")) {
      return "content-less-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".scss")) {
      return "content-scss-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".css")) {
      return "content-css-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".json")) {
      return "content-json-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".tar")) {
      return "content-tar-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".rar")) {
      return "content-rar-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".iso")) {
      return "content-iso-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".7z")) {
      return "content-7z-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".zip")) {
      return "content-zip-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".xls")
      || content_name?.toLowerCase()?.includes(".csv")
      || content_name?.toLowerCase()?.includes(".ods")) {
      return "content-xlsx-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".pdf")) {
      return "content-pdf-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".ppt")
      || content_name?.toLowerCase()?.includes(".odp")) {
      return "content-pptx-icon";
    } 
    else if (content_name?.includes(".rtf")) {
      return "content-rtf-icon";
    } 
    else if (content_name?.includes(".txt")) {
      return "content-text-icon";
    } 
    else if (
      content_name?.toLowerCase()?.includes(".doc")
      || content_name?.toLowerCase()?.includes(".sfdt")
      || content?.contentFileType?.includes("text")
    ) {
      return "content-docx-icon";
    } 
    else {
      return "null";
    }
  }

  const handleViewContent = async (view_content_item: any) => { 
    const previous_path = location.pathname;
    const view_content: any = {
      ...view_content_item,
      previous_path,
    };
    await setKeyValue("view_content_data", view_content);
    // dispatch(setViewContentData(view_content));
    navigate(`/contents/view/${view_content?.contentId}`);
  };

  const getContentName = (content_name?: any) => {
    if (content_name?.length < 17 || !content_name?.trim()) {
      return content_name;
    }
    const result = modifyString(content_name, 12, 7, "..");
    return result;
  }

  // view content area
  // const getViewContentData = async () => {
  //   const view_content = await getKeyValue("view_content_data");
  //   console.log({ view_content });
  //   if (view_content?.contentId) {
  //       dispatch(setViewContentData(view_content));
  //       // setTimeout(() => setLoading(false), 1000);
  //   } else {
  //     dispatch(setViewContentData(null));
  //   }
  // };

  // useEffect(() => {
  //   if (!view_content_data) {
  //     getViewContentData();
  //   }
  // }, [view_content_data]);


  return (
    <React.Fragment>
      <>
      {/* New Video content ----- Start */}
      {/* {
        videoFile
        ? <VideoDurationCheck videoFile={videoFile} handleUploadFile={handleUploadFile}/>
        : null
      } */}
      {/* New Video content ----- End */}

      {/* Previous Video content for new version ----- Start */}
      {/* {
        newVersionVideoFile
        ? <VideoDurationCheck videoFile={newVersionVideoFile} handleUploadFile={handleUploadNewVersionFile}/>
        : null
      } */}
      {/* Previous Video content for new version ----- End */}

      {/* Content Uploading Time */}
      {
        // uploadingProgressCount ?
        // <ProgressBar 
        //   fileName={"test.jpg"}
        //   progress={is_content_uploading_error ? 30 : !show_uploading_progress ? 100 : uploadingProgressCount}
        //   error={is_content_uploading_error}
        // />
        uploading_file ?
        <ProgressBar 
          fileName={uploading_file?.name}
          progress_count={uploadingProgressCount}
          error={is_content_uploading_error}
          error_message={is_content_uploading_error}
        />
        // <Dialog open={true} crossIcon={false}>
        //   <div className='loader-container'>
        //     <h3 className='loader-title'>File being uploaded</h3>
        //     <div className='loader-body'>
        //       <Progress
        //         percent={is_content_uploading_error ? 30 : !show_uploading_progress ? 100 : uploadingProgressCount}
        //         size="small"
        //         status={is_content_uploading_error ? "exception" : !show_uploading_progress ? "success" : uploadingProgressCount !== 100 ? "active" : "exception"}
        //         // strokeColor={"#108ee9"}
        //         showInfo={true}
        //         trailColor={"#385682"}
        //       // strokeColor={"#00b300"}
        //       />
        //     </div>
        //   </div>
        // </Dialog>
        : null
      }
      
      {
        modalType === "create-folder"
        ? <GlobalModal 
            handleCancel={() => {
              dispatch(setOpenGlobalModal(null));
              setModalType(null);
            }}
          >
            <CreateFolderForm parentCallback={(data: any) => handleCreateContent({ contentPayload: {folderName: data, isForFolder: true}, file: null, generate_response_data: null})} />
          </GlobalModal>
        : null
      }
      </>
      
      <div className="content-section-container">
        <ContentLeftBar/>
        <div className={"content-section-management"}>
          <InfiniteScroll
            dataLength={path_contents?.length}
            // className="border"
            scrollThreshold={0.9}
            style={{ overflowY: "scroll", paddingRight: 0, paddingBottom: 10 }}
            next={() => {
                console.log( `prev -> 10, next -> ${path_contents?.length}`);
                fetchMoreData();
            }}
            inverse={false}
            hasMore={hasMore}
            // hasMore={true}
            loader={null
              // isLoading && contents?.length > 9 ? (
              //   <div className="d-flex justify-content-center align-items-center mt-1">
              //   <Spinner
              //       size="sm"
              //       animation="border"
              //       variant="primary"
              //   />
              //   </div>
              // ) : null
            }
            scrollableTarget="scrollableDiv"
            // endMessage={
            //   <p style={{ textAlign: "center" }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
            height={"92vh"} // 43.5vh
          >
            <div className={`search-section`}>
              <div className="search-wrap">
                <div className="search-container">
                  <DebounceInput
                    minLength={0}
                    debounceTimeout={500}
                    value={searchText || ""}
                    onChange={(e: any) => setSearchText(e.target.value)}
                    type="search"
                    placeholder={"Search"}
                  />
                  {/* <input onChange={(e: any) => handleSearch(e.target.value)} type="search" alt="" placeholder="Search" /> */}
                  <img className="search-icon" src={search} alt="search" />
                </div>
              </div>
              
              {/* Add New */}
              <div className='content-buttons'>
                {
                  category === "archives" || category === "sharedWithMe"
                  ? null
                  : <DropdownForm
                      openDropdown={isOpenAddNewButton}// isOpenAddNewButton -> that "flag" will be used for form from menu
                      setOpenDropdown={setOpenAddNewDropdown}
                      menu={menu}
                      // disabled={category === "archives" || category === "sharedWithMe" ? true : false}
                    />
                }
                
              </div>
            </div>

            <div className='bread-crumb-section'>
              {
                breadCrumbPaths?.length
                ? <>
                    {
                      breadCrumbPaths?.map((item: any, idx: number) => (
                        idx === 0
                        ? <div 
                            key={item?.id} 
                            className='bread-crumb-title'
                            onClick={() => handleManageCurrentFolderContent(item)}
                          >
                            <img style={{cursor: 'auto'}} src={getImage(item?.icon_title || "team-folder-icon")} alt="content-path-icon" />
                            <span>{item?.name}</span>
                          </div>
                        :  <div 
                            key={item?.id}
                            className='bread-crumb-title'
                            onClick={() => handleManageCurrentFolderContent(item)}
                          >
                            <img style={{cursor: 'auto'}} src={getImage("big-back-arrow")} alt="content-path-icon" />
                            <span>{item?.name || "NextChild.displayName"}</span>
                          </div>
                      ))
                    }
                    
                  </>
                : null
              }
            </div>

            {
              breadCrumbPaths?.length === 1 && recentFiles?.length
              ? <div className="recent-contents-section">
                  <div className="recent-title">Recent</div>
                  <div className="recent-body">
                    {
                      recentFiles?.filter((item: any) => !item?.isFolder)?.slice(0, 6)
                      ?.map((item: any, idx: number) => (
                        <div key={idx} title={item?.contentFileName}
                          onClick={() => {
                            if (item?.isDeleted) {
                              dispatch(setErrorMessage("Error! File deleted form the system"));
                              return;
                            }
                            // if (item?.isArchived) {
                            //   dispatch(setErrorMessage("This content is already archived"));
                            //   return;
                            // }
                            handleViewContent(item);
                          }}
                          className='recent-content-container'
                        >
                          {/* <img src={getImage("removable_content")} alt="" /> */}
                          <div className="image-container">
                            <img src={getImage(getContentIconTitle(item))} alt="content-icon" />
                            {/* <img src={item?.presignedUrl || item?.url} alt="content-icon" /> */}
                          </div>
                          <div className='recent-content-title'>{getContentName(item?.contentFileName)}</div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              : null
            }

            {
              folder_paths?.length && path_contents?.length
              ? <div className="actionable-contents-section">
                  <div className="parent-content-table" style={{ borderRadius: "5px 5px", overflow: 'hidden'}}>
                    <ParentContents parentFolderPaths={breadCrumbPaths} category={category} concatFolderContent={concatFolderContent} updateContentIntoStateAndDB={updateContentIntoStateAndDB} content_data={path_contents} />
                  </div>
                </div>
              : !is_content_deleted && !path_contents?.length && isLoading ?
                <div className="actionable-contents-section">
                  <div className="parent-content-table" style={{ borderRadius: "0 0 5px 5px"}}>
                    <div className='table-responsive'>
                      <table className="table">
                        <thead className="top-table">
                          <tr>
                            <th>Name</th>
                            <th className='action-date-icon-container'>Added Date</th>
                            <th>Size</th>
                            <th style={{width: '180px'}}>Created By</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[1, 2, 3, 4]?.map((item: any) => (
                          <tr key={item} style={{borderBottom: item === 4 ? "none" : "0.5px solid #C7CDD6"}}>
                            <td><SkeletonLoader width={"100%"} height={"26px"} borderRadius={"10px"} /></td>
                            <td className='action-date-icon-container'>
                              <SkeletonLoader width={"60%"} height={"26px"} borderRadius={"10px"} />
                            </td>
                            <td><SkeletonLoader width={"70%"} height={"26px"} borderRadius={"10px"} /></td>
                            <td><SkeletonLoader width={"70%"} height={"26px"} borderRadius={"10px"} /></td>
                            <td><SkeletonLoader width={"70%"} height={"26px"} borderRadius={"10px"} /></td>
                          </tr>
                          ))}    
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              : breadCrumbPaths?.length === 1 && recentFiles?.length && !path_contents?.length
              ? null
              : (!path_contents?.length && (is_content_deleted || not_found_data) ) ? 
                    // <div className='no-data-found'>No data found</div>
                  <div className="actionable-contents-section">
                    <div className="parent-content-table">
                      <div className='table-responsive'>
                        <table className="table">
                          <thead className="top-table">
                            <tr>
                              <th style={{width: 420}}>Name</th>
                              <th style={{width: 265}}>Added Date</th>
                              <th>Size</th>
                              <th style={{width: 150}}>Created By</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody style={{display: 'none'}}>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>   
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="parent-content-table" style={{borderTop: 'none', borderRadius: "0 0 5px 5px", marginTop: "-1px"}}>
                      <div className='table-responsive'>
                        <table className="table">
                          <thead className="top-table" style={{display: 'none'}}>
                            <tr><th></th></tr>
                          </thead>
                          <tbody>
                            <tr style={{borderBottom: "none", borderRadius: "0 0 5px 5px"}}>
                              <td className='action-date-icon-container'  style={{width: "100%", height: `60vh`}}>
                                <div className='nothing-to-show'>
                                  <img src={getImage("nothing-to-show")} alt="not-found-data" />
                                  <p>Nothing to show here</p>
                                </div>
                              </td>
                            </tr>   
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
              : null
            }
          </InfiniteScroll>
        </div>
      </div>

    </React.Fragment>
  )
}

export default ContentComponent;
