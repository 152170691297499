import { Fragment, useEffect } from "react";
// import {
//   // BrowserRouter as Router, Link, NavLink,
//   useNavigate
// } from 'react-router-dom';
import './menu.scss';
import clap_up_logo from '../../../assets/img/ClapUp-Icon.svg';
// import cross_icon from "../../../assets/img/close-circle.svg";
// import PermissionCheck from "../../PermissionCheck";
import {
  useDispatch,
  // useSelector
} from "react-redux";
import {
  changePage,
  // resetUnifiedInbox, resetWallUnifiedInbox
} from "../../../unified/reducer";
import { menu_routes, wall_menu_routes, settings_routes } from "./menu.config";
import { useLocation } from "react-router-dom";

const Navigation = (props: any) => {
  const location = useLocation(); // Get the full path, e.g., "/all-messages/team-18e5258c-8080-11ef-8799-a627c6d8ad73"
  // Extract the base path "/all-messages"
  const basePath = location.pathname.split("/")[1]; // Get the first segment after "/"
  const fullBasePath = `/${basePath}`; // Add the leading slash
  
  const path = window.location.pathname;
  const query = window.location.search;
  // const { isReplyingOn } = useSelector((state: any) => state?.unified);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log({ path });
  }, [path]);

  const handleChangePage = (page?: any) => {
    // console.log({ page });
    let url = page + query;
    url = url.split('&')[0];
    console.log({ url, page });
    // dispatch(resetUnifiedInbox());
    // dispatch(resetWallUnifiedInbox());
    if (page === '/dashboard') {
      dispatch(changePage('/dashboard'))
      // if (isReplyingOn) {
      //   dispatch(changePage('/dashboard'))
      // } else {
      //   // dispatch(resetUnifiedInbox());
      //   // dispatch(resetWallUnifiedInbox());
      //   navigate('/dashboard');
      // }
      // navigate('/dashboard');
    }
    else if (page?.includes("/all-messages")) {
      dispatch(changePage("/all-messages?all=conversations"))
      // if (isReplyingOn) {
      //   dispatch(changePage(url))
      // } else {
      //   // dispatch(resetUnifiedInbox());
      //   // dispatch(resetWallUnifiedInbox());
      //   navigate(url);
      // }
      // isReplyingOn ? dispatch(changePage(url)) : navigate(url);
      // if (url.includes('?all=content')) {
      //   url = url.replace('?all=content', '');
      //   navigate(url);
      // } else {
      //   navigate(url);
      // }
    }
    else if (page?.includes("/bot-messages")) {
      dispatch(changePage("/bot-messages?all=conversations"))
      // if (isReplyingOn) {
      //   dispatch(changePage(url))
      // } else {
      //   // dispatch(resetUnifiedInbox());
      //   // dispatch(resetWallUnifiedInbox());
      //   navigate(url);
      // }
      // isReplyingOn ? dispatch(changePage(url)) : navigate(url);
      // if (url.includes('?all=content')) {
      //   url = url.replace('?all=content', '');
      //   navigate(url);
      // } else {
      //   navigate(url);
      // }
    }
    else if (page?.includes("/promotions")) {
      dispatch(changePage("/promotions?campaign=promotions"))
      // if (isReplyingOn) {
      //   dispatch(changePage(url))
      // } else {
      //   // dispatch(resetUnifiedInbox());
      //   // dispatch(resetWallUnifiedInbox());
      //   navigate(url);
      // }
      // isReplyingOn ? dispatch(changePage(url)) : navigate(url);
      // if (url.includes('?all=content')) {
      //   url = url.replace('?all=content', '');
      //   navigate(url);
      // } else {
      //   navigate(url);
      // }
    }
    else if (page?.includes("/settings")){
      dispatch(changePage(page))
    }
    else if (page?.includes('wall') || page?.includes('conversations')) {
      dispatch(changePage(page))
      // if (isReplyingOn) {
      //   dispatch(changePage(url))
      // } else {
      //   // dispatch(resetUnifiedInbox());
      //   // dispatch(resetWallUnifiedInbox());
      //   navigate(url);
      // }
      // isReplyingOn ? dispatch(changePage(url)) : navigate(url);
      // if (url.includes('?all=content')) {
      //   url = url.replace('?all=content', '');
      //   navigate(url);
      // } else {
      //   navigate(url);
      // }
    }
    else {
      dispatch(changePage(page))
      // if (isReplyingOn) {
      //   dispatch(changePage(page))
      // } else {
      //   // dispatch(resetUnifiedInbox());
      //   // dispatch(resetWallUnifiedInbox());
      //   navigate(page);
      // }
      // isReplyingOn ? dispatch(changePage(page)) : navigate(page);
    }
  }

  const menu_data = query?.includes('wall') ?  wall_menu_routes : menu_routes;
  // console.log({menu_data});
  return (
    <Fragment>
      <div className="nav-wrapper">
        <nav className="text-center">
          <img
            className="nav-logo-icon"
            src={clap_up_logo}
            alt=""
          />
          <ul>
            {menu_data?.map((item: any) => {
              return (
                // <PermissionCheck permissionName={item.permissionName} key={item?.id}>
                  <li title={item?.title} className={`li-${item?.id}`} key={item?.id}>
                    <button
                      type="button"
                      className={`${(path === item?.url || (fullBasePath?.includes("all-messages") && item?.url?.includes(fullBasePath)) || (path === "/" && item?.root) || path?.includes(item?.sub_key) || path?.includes(item?.sub_key1) || item?.unified_sub_keys?.find((key: any) => key === path)) && "active"}`}
                      onClick={() => path !== item?.url && handleChangePage(item?.url)}
                    >
                      <img src={item?.icon} alt="" />
                    </button>
                  </li>
                // </PermissionCheck>
              )
            })}
          </ul>
        </nav>
        
        <nav className="text-center">
          <ul>
            {settings_routes?.map((item: any) => {
              return (
                // <PermissionCheck permissionName={item.permissionName} key={item?.id}>
                  <li title={item?.title} className={`li-${item?.id}`} key={item?.id}>
                    <button
                      type="button"
                      className={`${(path === item?.url || (path === "/" && item?.root) || path?.includes(item?.sub_key)) && "active"}`}
                      onClick={() => path !== item?.url && !path?.includes("settings") && handleChangePage(item?.url)}
                    >
                      <img src={item?.icon} alt="" />
                    </button>
                  </li>
                // </PermissionCheck>
              )
            })}
          </ul>
        </nav>

      </div>
    </Fragment>
  );
}

export default Navigation;