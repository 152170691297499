// import React from 'react';
import Routing from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css'; // Import Quill Snow theme
import 'react-quill/dist/quill.bubble.css'; // Import Quill Bubble theme
import Success from './components/Notifications/Success';
import Error from './components/Notifications/Error';
import './App.scss';
// import UnPermission from './pages/un-permission/UnPermission';

const App = () => {

  return (
    <div className="App">
      <Routing />
      <Success />
      <Error />
    </div>
  );
}

export default App;
