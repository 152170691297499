import React, { useEffect, useState } from 'react';
const ImageViewer = ({ content }: any) => {
    const [scale, setScale] = useState(1);

     // Zoom in and zoom out functions
     const zoomIn = () => setScale((prev) => prev + 0.01);
     const zoomOut = () => setScale((prev) => (prev > 0.02 ? prev - 0.01 : prev));
 
     // Handle mouse scroll for zooming
     const handleWheel = (event: React.WheelEvent) => {
         if (event.ctrlKey) {
            event.preventDefault(); // Prevent default browser zoom behavior
            event.stopPropagation();
            event.deltaY < 0 ? zoomIn() : zoomOut();
        }
     };

     // Prevent full window zooming globally
    useEffect(() => {
        const preventGlobalZoom = (event: WheelEvent) => {
            if (event.ctrlKey) {
                event.preventDefault();
            }
        };

        window.addEventListener("wheel", preventGlobalZoom, { passive: false });
        return () => window.removeEventListener("wheel", preventGlobalZoom);
    }, []);
 
     // Handle keyboard shortcuts for zooming
     useEffect(() => {
         const handleKeyDown = (event: KeyboardEvent) => {
             if (event.ctrlKey && (event.key === "=" || event.key === "+")) {
                 event.preventDefault();
                 zoomIn();
             } else if (event.ctrlKey && event.key === "-") {
                 event.preventDefault();
                 zoomOut();
             }
         };
 
         window.addEventListener("keydown", handleKeyDown);
         return () => window.removeEventListener("keydown", handleKeyDown);
     }, []);
    return (
        <React.Fragment>
            <div className="image-view-container scroll-container"
                onWheel={handleWheel} // Attach scroll event
                tabIndex={0} // Ensure the div is focusable for keyboard events
            >
                <img 
                    style={{
                        transform: `scale(${scale})`, 
                        // transformOrigin: "center",
                        transition: 'transform 0.25s ease'
                    }}
                    src={content?.presignedUrl} 
                    alt="content-image" 
                />
                {/* <div className="zoom-controls">
                    <button onClick={zoomIn}>+</button>
                    <button onClick={zoomOut}>-</button>
                </div> */}
            </div>
        </React.Fragment>
    )
}

export default ImageViewer;