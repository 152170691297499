// Utility to open or create an IndexedDB database
const openDatabase = (): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("StorageDB", 3);

        // Handle database upgrades
        request.onupgradeneeded = () => {
            const db = request.result;


            // Create object store for key-value pairs if not already created
            if (!db.objectStoreNames.contains("keyValueStore")) {
                db.createObjectStore("keyValueStore", { keyPath: "key" }); // keyPath = "key"
            }

            // Create object store for files if not already created
            if (!db.objectStoreNames.contains("files")) {
                db.createObjectStore("files", { keyPath: "key" });
            }

            // Create object store for key-value pairs signalR sender info
            if (!db.objectStoreNames.contains("senderDataStore")) {
                db.createObjectStore("senderDataStore", { keyPath: "key" }); // keyPath = "key"
            }
        };

        request.onsuccess = () => resolve(request.result);
        request.onerror = (event: any) => reject(event.target?.error || "Error opening database");
    });
};

// ======= Get All keys from stores ========
const getAllKeysFromStore = async (storeName: string): Promise<any[]> => {
    const db = await openDatabase();

    return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, "readonly");
        const objectStore = transaction.objectStore(storeName);

        // Get all keys
        const request = objectStore.getAllKeys();

        request.onsuccess = () => resolve(request.result);
        request.onerror = (event: any) => reject(event.target?.error || `Error fetching keys from ${storeName}`);
    });
};

const getAllKeysFromAllStores = async (): Promise<Record<string, any[]>> => {
    const db = await openDatabase();
    const storeNames = Array.from(db.objectStoreNames); // Get all store names
    const keysFromStores: Record<string, any[]> = {};

    for (const storeName of storeNames) {
        keysFromStores[storeName] = await getAllKeysFromStore(storeName);
    }

    return keysFromStores;
};

// ========== SignalR Sender Management =========
// Utility to put a sender key-value in IndexedDB
const setSenderKeyValue = async (key: string, value: any) => {
    try {
        const db = await openDatabase();
        const transaction = db.transaction("senderDataStore", "readwrite");
        const store = transaction.objectStore("senderDataStore");

        // Store the key-value pair
        store.put({ key, value }); // `put` will add or update the entry

        return new Promise((resolve, reject) => {
            transaction.oncomplete = () => resolve("Added Successfully");
            transaction.onerror = (error: any) => reject(error.target?.error || "Error saving data");
        });
    } catch (error) {
        console.log("Error in SenderKeyValue:", error);
        throw new Error("Failed to set key-value pair");
    }
};

// Utility to get a sender value by key in IndexedDB
const getSenderKeyValue = async (key: string): Promise<any> => {
    const db = await openDatabase();
    const transaction = db.transaction("senderDataStore", "readonly");
    const store = transaction.objectStore("senderDataStore");

    return new Promise((resolve, reject) => {
        const request = store.get(key);

        request.onsuccess = () => resolve(request.result?.value); // Return only the value
        request.onerror = () => reject(request.error);
    });
};

// Utility to delete a sender value by key in IndexedDB
const deleteSenderKeyValue = async (key: string) => {
    const db = await openDatabase();
    const transaction = db.transaction("senderDataStore", "readwrite");
    const store = transaction.objectStore("senderDataStore");

    store.delete(key);

    return new Promise((resolve, reject) => {
        transaction.oncomplete = () => resolve("Deleted SuccessFully");
        transaction.onerror = () => reject(transaction.error);
    });
};

// Utility to get all sender keys-values in IndexedDB
const getAllSenderKeyValues = async (): Promise<{ key: string; value: any }[]> => {
    const db = await openDatabase();
    const transaction = db.transaction("senderDataStore", "readonly");
    const store = transaction.objectStore("senderDataStore");

    return new Promise((resolve, reject) => {
        // there all pairs are like, <key> = messageId + ticket_no 
        const request = store.getAll();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
};

// Utility to clear a object store -> clear all sender data 
const clearSenderKeyValueStoredData = async () => {
    const db = await openDatabase(); // Reuse the `openDatabase` function from the previous example
    const transaction = db.transaction("senderDataStore", "readwrite");
    const store = transaction.objectStore("senderDataStore");

    store.clear(); // Clears all data in the sender-value object store

    return new Promise((resolve, reject) => {
        transaction.oncomplete = () => resolve(true);
        transaction.onerror = () => reject(transaction.error);
    });
};
// ========== SignalR Sender Management =========

// ========== Key Value Management =========
// Utility to put a key-value in IndexedDB
const setKeyValue = async (key: string, value: any) => {
    try {
        localStorage.setItem("index_session", "true");
        const db = await openDatabase();
        const transaction = db.transaction("keyValueStore", "readwrite");
        const store = transaction.objectStore("keyValueStore");

        // Store the key-value pair
        store.put({ key, value }); // `put` will add or update the entry

        return new Promise((resolve, reject) => {
            transaction.oncomplete = () => resolve("Added Successfully");
            transaction.onerror = (error: any) => reject(error.target?.error || "Error saving data");
        });
    } catch (error) {
        console.log("Error in setKeyValue:", error);
        throw new Error("Failed to set key-value pair");
    }
};

// Utility to get a value by key in IndexedDB
const getKeyValue = async (key: string): Promise<any> => {
    const db = await openDatabase();
    const transaction = db.transaction("keyValueStore", "readonly");
    const store = transaction.objectStore("keyValueStore");

    return new Promise((resolve, reject) => {
        const request = store.get(key);

        request.onsuccess = () => resolve(request.result?.value); // Return only the value
        request.onerror = () => reject(request.error);
    });
};

// Utility to delete a value by key in IndexedDB
const deleteKeyValue = async (key: string) => {
    const db = await openDatabase();
    const transaction = db.transaction("keyValueStore", "readwrite");
    const store = transaction.objectStore("keyValueStore");

    store.delete(key);

    return new Promise((resolve, reject) => {
        transaction.oncomplete = () => resolve("Deleted SuccessFully");
        transaction.onerror = () => reject(transaction.error);
    });
};

// Utility to get all keys-values in IndexedDB
const getAllKeyValues = async (): Promise<{ key: string; value: any }[]> => {
    const db = await openDatabase();
    const transaction = db.transaction("keyValueStore", "readonly");
    const store = transaction.objectStore("keyValueStore");

    return new Promise((resolve, reject) => {
        const request = store.getAll();

        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
};

// Utility to update a value by key in IndexedDB
const updateKeyValue = async (key: string, newValue: any) => {
    const res = await setKeyValue(key, newValue);
    return res;
};

// Utility to clear a object store -> clear all data 
const clearKeyValueStoredData = async () => {
    const db = await openDatabase(); // Reuse the `openDatabase` function from the previous example
    const transaction = db.transaction("keyValueStore", "readwrite");
    const store = transaction.objectStore("keyValueStore");

    store.clear(); // Clears all data in the key-value object store

    return new Promise((resolve, reject) => {
        transaction.oncomplete = () => resolve(true);
        transaction.onerror = () => reject(transaction.error);
    });
};

// ========== Key Value Management =========
// =========== Attachment Management ===========

// Utility to save a file in IndexedDB
const saveFile = async (key: string, file: File) => {
    const db = await openDatabase();
    const transaction = db.transaction("files", "readwrite");
    const store = transaction.objectStore("files");

    const record = { key, file };
    store.put(record);

    return new Promise((resolve, reject) => {
        transaction.oncomplete = () => resolve(true);
        transaction.onerror = () => reject(transaction.error);
    });
};

// Utility to get all files from IndexedDB
const getAllFiles = async (): Promise<{ key: string; file: File }[]> => {
    const db = await openDatabase();
    const transaction = db.transaction("files", "readonly");
    const store = transaction.objectStore("files");

    return new Promise((resolve, reject) => {
        const request = store.getAll();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
};

// Utility to delete a file by key
const deleteFile = async (key: string) => {
    const db = await openDatabase();
    const transaction = db.transaction("files", "readwrite");
    const store = transaction.objectStore("files");

    store.delete(key);

    return new Promise((resolve, reject) => {
        transaction.oncomplete = () => resolve("Deleted Successfully");
        transaction.onerror = () => reject(false);
        // transaction.onerror = () => reject(transaction.error);
    });
};

// Utility to clear a object store -> clear all data 
const clearStoredAttachmentFileData = async () => {
    const db = await openDatabase(); // Reuse the `openDatabase` function from the previous example
    const transaction = db.transaction("files", "readwrite");
    const store = transaction.objectStore("files");

    store.clear(); // Clears all data in the `files` object store

    return new Promise((resolve, reject) => {
        transaction.oncomplete = () => resolve(true);
        transaction.onerror = () => reject(transaction.error);
    });
};
// =========== Attachment Management ===========

// Utility to delete db
const deleteDatabase = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.deleteDatabase("StorageDB"); // Replace "StorageDB" with your database name

        request.onsuccess = () => {
            console.log("Database deleted successfully");
            resolve(true);
        };
        request.onerror = () => {
            console.error("Failed to delete database:", request.error);
            reject(request.error);
        };
        request.onblocked = () => {
            console.warn("Delete blocked! Ensure no open connections to the database.");
        };
    });
};



export {
    openDatabase, getAllKeysFromAllStores, deleteDatabase,
    // SignalR sender Management
    setSenderKeyValue, getSenderKeyValue, deleteSenderKeyValue, getAllSenderKeyValues, clearSenderKeyValueStoredData,
    // Key Value Management
    setKeyValue, getKeyValue, deleteKeyValue, getAllKeyValues, updateKeyValue, clearKeyValueStoredData,
    // Attachment Management
    saveFile, getAllFiles, deleteFile, clearStoredAttachmentFileData,
};