import React from 'react';
import { getImage } from '../../../../unified/image_service';

const UnavailableViewer = ({ content }: any) => {

    return (
        <React.Fragment>
            <div className="unavailable-view-container">
                <div className='unavailable-preview'>
                    <p>Preview Unavailable</p>
                    <img src={getImage("big-circle-exclamation")} alt="unavailable-preview" />
                </div>
            </div>
        </React.Fragment>
    )
}

export default UnavailableViewer;