import { takeEvery, put, call, all, select } from 'redux-saga/effects';
import Constants from '../../../constants';
import axios from "axios";

import { setRoleList, setWorkspaces } from './headerReducer';
import { generateAccessToken } from '../../../profile/reducer';
import { api_url, api_version } from '../../../utils/server';
import { getKeyValue } from '../../../utils/indexedDBManagementService';

const selectData = (state: any) => state.headerState; // 

// Worker Saga:
function* findWorkspaces(): Generator<any> {
    
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        // console.log("header -> Worker saga => getWorkspacesSaga action===>");
        const cuid = logged_in_user_secret_data?.cuid;
        if (!cuid) {
            return;
        }
        let url = api_url + `/api/${api_version}/Clapup/GetWorkspaceList?clapupId=` + cuid;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setWorkspaces(data));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('setWorkspaces error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* findUserRoles(action: any): Generator<any> {
    // console.log("header -> Worker saga => findUserRoles action===>", action);
    try {
        const { roles } = yield select(selectData);
        if (roles?.length) {
            yield put(setRoleList(roles));
            return;
        }
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        let url = api_url + `/api/${api_version}/ClapupIdentity/AccessLevelwiseRoleList`;
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setRoleList(data));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Watcher Saga:
function* headerSaga() {    
    yield all([
        takeEvery(Constants.FIND_WORKSPACE_LIST, findWorkspaces),
        takeEvery(Constants.GET_ROLES, findUserRoles),
    ]);
}

export default headerSaga;