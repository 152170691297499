import React,  { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteKeyValue, getKeyValue } from '../../utils/indexedDBManagementService';
import './ContentComponent.scss';
import { getImage } from '../../unified/image_service';
import ViewTabHeader from './ManageContents/ViewDetailsContent/ViewTabHeader';
import ViewDetails from './ManageContents/ViewDetailsContent/ViewDetails';
import ContentViewer from './ManageContents/ViewContents/ContentViewer';
import { download2Content, modifyString } from '../../utils/mediaService';
import { setErrorMessage } from '../../unified/reducer';
import { useDispatch } from 'react-redux';
import MultipleSkeletonLoaders from '../Loaders/SkelitonLoader/MultipleSkeletonLoaders';
// import { setViewContentData } from './reducer';

const ContentView = ({}: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { params } = useParams<{ params: string }>();
    const [view_content_data, setViewContentData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const getContentData = async () => {
        const view_content = await getKeyValue("view_content_data");
        if (view_content?.contentId === params) {
            setViewContentData(view_content);
            setTimeout(() => setLoading(false), 1000);
        } else {
            if (view_content?.previous_path) {
                await deleteKeyValue("view_content_data");
                navigate(view_content?.previous_path);
            } else {
                await deleteKeyValue("view_content_data");
                navigate('/contents/shared-files');
            }
        }
    };
    
    useEffect(() => {
        // console.log({ view_content });
        getContentData();
    }, [ params]);
    
    const handleCancel = async() => {
        await deleteKeyValue("view_content_data");
        // dispatch(setViewContentData(null));
        navigate(view_content_data?.previous_path);
    }

    const getFileSize = (sizeInKB: string): string => {
        try {
          const size = parseFloat(sizeInKB?.replace(" KB", "").trim());
          if (isNaN(size)) {
            throw new Error("Invalid size");
          }
        
          if (size >= 1024 * 1024) {
            return `${(size / (1024 * 1024)).toFixed(2)} GB`;
          } else if (size >= 1024) {
            return `${(size / 1024).toFixed(2)} MB`;
          } else {
            return `${size.toFixed(2)} KB`;
          }
          
        } catch (err: any) {
          return "N/A"
        }
    };

    return (
        <React.Fragment>
            <div className='view-container'>
                {
                    view_content_data
                    ? 
                    <div className="content-view-container">
                        <div className="content-view-header">
                            <div title={view_content_data?.contentFileName} className="file-name">{view_content_data?.contentFileName?.length < 50 ? view_content_data?.contentFileName : modifyString(view_content_data?.contentFileName, 40, 7, "...")}</div>
                            <div className="action-button-container">
                                <button><img src={getImage("view-delete-icon")} alt="action-btn"/></button>
                                <button className="action-text-btn"
                                    onClick={() => console.log("Save Content")}
                                >
                                    Save
                                </button>
                                <button className="action-text-btn"
                                    onClick={async () => {
                                        const contentUrl = await download2Content(view_content_data);
                                        // console.log({ contentUrl });
                                        if (!contentUrl) {
                                          dispatch(setErrorMessage("Something went wrong, please try again letter"))
                                        }
                                    }}
                                >
                                    Download
                                </button>
                                <button><img onClick={handleCancel} src={getImage("x-icon")} alt="action-btn"/></button>
                            </div>
                        </div>
                        <div className="content-view-body">
                            <div className="view-body">
                                <ContentViewer content={view_content_data}/>
                            </div>
                            <div className="view-details-container">
                                <div className="view-details">
                                    <ViewTabHeader
                                        tabs={["Properties", "Versions", "Discussions", "History"]}
                                        handleChangeTab={(data: any) => console.log(data)}
                                        value={"Properties"}
                                    />
                                    <ViewDetails
                                        list_data={{
                                            label_1: "File Name", value_1: view_content_data?.contentFileName || "",  
                                            label_2: "Fils Size", value_2: getFileSize(view_content_data?.contentSize || ""),  
                                            label_3: "Updated by", value_3: view_content_data?.createdByName || "",  
                                            label_4: "Updated on", value_4: view_content_data?.createdOn || "",  
                                            label_5: "Last updated by", value_5: view_content_data?.updatedByName || "",  
                                            label_6: "Last updated on", value_6: view_content_data?.updatedOn || "",  
                                            label_7: "Tag", value_7: {tagId: "123", tagColor: "blue", tagName: "Tag Name"},
                                            properties_Keywords: view_content_data?.properties_Keywords,  
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    : loading ? <div className="loading-container">
                        <MultipleSkeletonLoaders/>
                    </div> 
                    : null
                }
            </div>
        </React.Fragment>
    )
}

export default ContentView;
