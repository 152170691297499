import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { getImage, getGenerateApiImageByName } from "../../../../unified/image_service";
import { DebounceInput } from 'react-debounce-input';
// import { setErrorMessage } from "../../../../Unified/reducer";
import { toggleUpdatedTeamContent, requestForAddingTeamMember } from "../teamsReducer";
import { modifyCurrentString, modifyString } from "../../../../utils/mediaService";

const AddUser: React.FC<any> = ({handleCancel}: any) => {
    const dispatch = useDispatch();
    const {logged_in_user_secret_data} = useSelector((state: any) => state?.login);
    const { setting_users, is_updated_team_content, editable_team, team_users } = useSelector((state: any) => state?.teamState);
    const [selectedTeamMembers, setSelectedTeamMembers] = useState<any>([]);
    const [userMembers, setUserMembers] = useState<any>(setting_users);
    useEffect(() => {
        // console.log({ team_users, setting_users });
        if (team_users?.length) {
            const selected_members = team_users?.map((member: any) => member?.id);
            setSelectedTeamMembers(selected_members);
        }
    }, [editable_team?.teamId]);


    useEffect(() => {
        if (is_updated_team_content) {
            dispatch(toggleUpdatedTeamContent(false));
            handleCancel();
        }
    }, [is_updated_team_content]);
    
    const handleAdd = () => {
        // if (!selectedTeamMembers?.length) {
        //     dispatch(setErrorMessage("Please select a user"));
        //     return;
        // }
        const workspaceId = logged_in_user_secret_data?.wuid || null;
        const roleId = logged_in_user_secret_data?.roleId || null;
        const data_payload = {
            workspaceId: workspaceId || "",
            clapupUserIds: selectedTeamMembers.join(',') || "",
            roleId: roleId || "",
            teamId: editable_team?.teamId,
        }
        console.log("Came for adding member...", { data_payload });
        dispatch(requestForAddingTeamMember(data_payload));
    }

    const handleSelectTeamMembers = (id: any) => {
        const already_selected = selectedTeamMembers?.find((member_id: any) => member_id === id);
        // console.log({ already_selected, selectedTeamMembers, id });
        if (already_selected) {
            const filteredSelectedTeamMembers = selectedTeamMembers?.filter((member_id: any) => member_id !== id);
            setSelectedTeamMembers(filteredSelectedTeamMembers);
        } else {
            const members = [...selectedTeamMembers, id];
            setSelectedTeamMembers(members);
        }
    }

    const handleSearchForTableColumn = (value: any) => {
        // console.log("value ==== ", value);
        const isEmailCheck = value?.includes('@');
        // const isNameCheck = value.includes(' ');
        // const isNumberCheck = value.includes('0') || value.includes('1') || value.includes('2') || value.includes('3') || value.includes('4') || value.includes('5') || value.includes('6') || value.includes('7') || value.includes('8') || value.includes('9');
        // console.log({userManagementState})
        if(isEmailCheck){
            const data = setting_users?.filter((item: any) => item?.email?.includes(value));
            setUserMembers(data);
            
        } else{
            const data = setting_users?.filter((item: any) => {
                return item?.userName?.toLowerCase().includes(value?.toLowerCase());
            })
            // .map((item: any) => {
            //     return {
            //         ...item,
            //         key: item.userId,
            //     }
            // }).slice(0, 10);
            // console.log("data ==== ", data);
        
            if(!data?.length){
                const data = setting_users?.filter((item: any) => item?.email?.includes(value));
                setUserMembers(data);
            }else{
                setUserMembers(data);
            }
        }
    }
    
    const getProperValue = (current_value?: any) => {
        if (current_value?.length < 27 || !current_value?.trim()) {
            return current_value;
        }
        const result = modifyString(current_value, 13, 12, "..");
        return result;
    }

    return (
        <React.Fragment>
            <div className="add-user-setting-table-form">
                <h3>Add Team Members</h3>
                <div className="settings-form-item">
                    <DebounceInput
                        type="text" 
                        placeholder={"Search users to add to team"}
                        minLength={0}
                        debounceTimeout={1000}
                        onChange={(event: any) => handleSearchForTableColumn(event.target.value)} 
                    />
                </div>

                <h4>People</h4>
                <div className="user-member-table">
                    <div className='table-responsive'>
                        <table className="table m-0">
                            <thead className="d-none"><tr><th>Image/Name</th><th>Email</th><th></th></tr></thead>
                            <tbody>
                                {userMembers?.map((user: any, idx: number) => (
                                    <tr key={idx} className={`${team_users?.find((sm: any) => sm?.id === user?.id) ? "d-none" : ""}`}>
                                        <td>
                                            <img src={getGenerateApiImageByName(user?.firstName + " " + user?.lastName)} alt="user" />
                                            <div title={user?.userName}>{modifyCurrentString(user?.userName, user?.userName?.length > 17 ? 15 : 17, user?.userName?.length > 17 ? "..." : " ")}</div>
                                        </td>
                                        <td title={user?.email}>{getProperValue(user?.email)}</td>
                                        <td>
                                            <img
                                                src={getImage(
                                                    selectedTeamMembers?.find((member_id: any) => member_id === user?.id ) ?
                                                    "checked-icon" 
                                                    : "un-checked-icon"
                                                )}
                                                alt="check-uncheck-icon"
                                                style={{ cursor: 'pointer' }} 
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevents triggering the row click
                                                    handleSelectTeamMembers(user?.id);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="action-buttons">
                    <div
                        className="text-button"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </div>
                    <div
                        className="text-button"
                        onClick={() => handleAdd()}
                    >
                        Add
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddUser;