import React, { Fragment, useEffect, useState } from "react";
// import { Tooltip } from 'antd';
import { Col, Row, Spinner } from "react-bootstrap";
// import Button from "../../button/button-container";
import '../AllFeeds.scss';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import search from '../../../assets/icons/search-icon.svg';
import emptyBox from '../../../assets/img/Empty.svg';
// import user_avatar from '../../assets/icons/user-avatar.svg';
// import google_logo from '../../assets/icons/google-logo.svg';
// import cross_icon from '../../../assets/icons/cross-svgrepo.svg';
import Search from "../../Search/SearchComponent";
import { useDispatch, useSelector } from "react-redux";
import { changePage, toggleIsWhatsappInboxEmpty, resetWallUnifiedInbox, toggleSharedWithMeInboxUpdated, toggleReplyingOn, triggerDeleteWallUnifiedData, triggerSearchWallData, triggerWallUnified, twitterMentions, toggleWhatsAppSenderState, setCurrentStateInboxConversation, setIsSearched, resetSearchAnyData, setIsDataComeFromSaga } from "../../../unified/reducer";
import { RootState } from "../../../store";
import connection from '../../../utils/signalRconnectionConversation';
// import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
// import CommonModal from "../../CommonModal/CommonModal";
// import RequestReview from "../../RequestReview/RequestReview";
// import { bio_url } from "../../../utils/server";
// import EmbedCode from "../../EmbedCode/EmbedCode";
// import { DebounceInput } from "react-debounce-input";
import Dialog from "../../Dialogs/Dialog";
import MessagesDetails from "../ConversationDetails/AllDetails/MessagesDetails";
import MessagesInboxCard from "../InboxCard/MessagesInboxCard";
import AllMessagesConversations from "../ConversationDetails/AllConversations/AllMessagesConversation";
import FeedLeftBar from "../FeedLeftBar/FeedLeftBar";
import FeedMessageTabs from "./FeedMessageTabs";
import moment from "moment";
import SkeletonLoader from "../../Loaders/SkelitonLoader/SkeletonLoader";
import { deleteKeyValue, setKeyValue } from "../../../utils/indexedDBManagementService";
import { getSettingTeamUserAndChannelList } from "../../../pages/Settings/Teams/teamsReducer";
// const capitalize = (word: string) => word[0].toUpperCase() + word.slice(1).toLowerCase();

interface Props {
  feedsEmpty?: boolean;
  feedsEmptyContent?: string;
  buttonContent?: string;
  imagePath?: any;
  handleTriggerData?: any;
  triggerData?: any;
  feedsContent?: any;
  type?: any;
  isTagChanged?: any;
  handleSearchData?: any;
}

const SharedWithMeMessageFeeds: React.FC<Props> = ({ type, handleSearchData, handleTriggerData, triggerData }) => {
  const route_path = window.location.pathname;
  // const path = window.location.pathname;
  const query_string = window.location.search;
  const tag_type = query_string?.includes("open") ? "open"
  : query_string?.includes("inProgress") ? "inProgress"
  : query_string?.includes("closed") ? "closed" 
  : query_string?.includes("starred") ? "starred" 
  : "all";
  const navigate = useNavigate();
  const [sText, setSearchText] = useState('');
  const {
      logged_in_user_secret_data,
      accessed_user_details } = useSelector((state: any) => state?.login);
  // const {integrationConnections}: any = useSelector((state: RootState) => state?.login);
  const {
    is_data_come_from_saga,
    SearchedSharedWithMeInboxData: search_inbox_data, is_searched,
    SharedWithMeInboxData: inbox_data, is_shared_with_me_inbox_data_updated,
    is_fetching, empty_data,
    searchText, searchPayload, searchStartDate, searchEndDate, searchFilterStatus,
    pageRoute, is_empty_whatsapp_inbox,
  }: any = useSelector((state: RootState) => state?.unified);
  const dispatch = useDispatch();
  
  const {
    allMails, closedMails,
    openMails, inProgressMails,
    // statDetails, msgDetails: chatDetails,
  }: any = search_inbox_data || inbox_data || {};

  // const DataObject:
  //   any = tag_type === "all"
  //     ? allMails || []
  //     : tag_type === "closed"
  //     ? closedMails
  //     : tag_type === "inProgress"
  //     ? inProgressMails
  //     : tag_type === "starred"
  //     ? []
  //     : tag_type === "open"
  //     ? openMails
  //     : tag_type ? []
  //     : allMails || [];

  const DataObject:
    any = tag_type === "all"
      ? allMails || []
      : tag_type === "closed"
      ? closedMails
      : tag_type === "inProgress"
      ? inProgressMails
      : tag_type === "starred"
      ? []
      : tag_type === "open"
      ? openMails
      // : tag_type ? []
      : allMails || [];
    
  const data_key = tag_type === "open"
  ? "openMails"
  : tag_type === "closed"
  ? "closedMails"
  : tag_type  === "inProgress"
  ? "inProgressMails"
  : tag_type === "starred"
  ? "starredMails"
  : "allMails";

  const [conversation, setConversation] = useState<any>(null);
    // const [stat_details, setStatDetails] = useState<any>(null);
  // const [conversationId, setConversationId] = useState<any>(null);
  // const [wallData, setWallData] = useState<any[]>([]);
  const [activePage, setActivePage] = useState<any>("conversations");
  const [activeTab, setActiveTab] = useState<any>("all");
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { emailDetails: InboxData, statDetails, lastEmailDetails, chatSummaryList, nextDate } = DataObject || {};
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const {params} = useParams();
  useEffect(() => { 
    if (is_fetching) {
      setIsLoading(true);
      setData([]);
    }
  }, [is_fetching]);

  useEffect(() => {
    // console.log({ empty_data, data_key, inbox_data });
    if (empty_data && !is_fetching) {
      setIsLoading(false);
    }
    else if (!empty_data && !is_fetching) {
      setIsLoading(false);
    }
    else if (inbox_data) {
      if (empty_data && inbox_data?.hasOwnProperty(data_key) && !inbox_data[data_key]?.emailDetails?.length) {
        // console.log({ empty_data, data_key, inbox_data: inbox_data[data_key]?.emailDetails });
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
    }
  }, [is_fetching, empty_data]);
  
  useEffect(() => { 
    // console.log({ data_key, DataObject });
    if ((!DataObject?.emailDetails?.length || tag_type === "starred") && !is_fetching) {
      setIsLoading(false);
      setData([]);
      setIsEmpty(true);
      // resetIsFetchingAndIsEmpty(); is_fetching: false, empty_data: false
    } else {
      setIsEmpty(false);
    }
  }, [tag_type]);

  // SharedWithMeInboxData
  const setInboxEmailDataInboxDB = async() => {
    if (inbox_data && is_data_come_from_saga) {
      console.log("Line - 167", { is_data_come_from_saga, inbox_data });
      dispatch(setIsDataComeFromSaga(false));
      setData(InboxData);
      await setKeyValue("SharedWithMeInboxData", inbox_data);
      // await deleteKeyValue("SharedWithMeInboxData");
    }
  };

  // for scrolling and fetching data by api but not searched
  useEffect(() => {
    if (is_data_come_from_saga) {
      setInboxEmailDataInboxDB();
    }
  }, [is_data_come_from_saga]);

  useEffect(() => {
    if (InboxData?.length && !empty_data) {
      setIsLoading(false);
      setData(InboxData);
    }
    else {
      setData(InboxData);
    }
  }, [InboxData?.length, params, empty_data]);
  
  // This data will not go to index_db
  useEffect(() => {
    // console.log("search Inbox Data: ",{ is_searched, DataObject });
    if (!data?.length && is_searched) {
      // console.log("search Inbox Data: ",{ InboxData });
      setIsLoading(false);
      setData(InboxData);
      // dispatch(setIsSearched(false));
    }
    else if (InboxData?.length && !is_searched) {
      setData(InboxData);
      setIsLoading(false);
    }
  }, [is_searched]);

  const filterBySearchData = (value?: any, key?: any) => {
    // dispatch
    console.log("key, value ======> ", {key, value});
    // setIsLoading(true);
    setData([]);
    if (!value) {
      dispatch(resetSearchAnyData());
      dispatch(setIsSearched(false));
    }
    handleSearchData(key, value);
  };
      
  

  // ===== SignalR start ====
  const {
    isReplyingOn,
    // whatsapp_replying_text,
    // whats_app_sender_state: whats_app_sender
  } = useSelector((state: RootState) => state?.unified);
  // // Join a workspace (replace with actual workspace ID)
  // const joinWorkspace = async (workspaceId: any) => {
  //   try {
  //       await connection.invoke("JoinWorkspace", workspaceId);
  //       console.log(`SignalR_SharedWithMe_Inbox: Joined workspace: ${workspaceId}`);
  //   } catch (error) {
  //       console.error("SignalR_SharedWithMe_Inbox: Error joining workspace:", error);
  //   }
  // };

  // // // Receiving hook
  // useEffect(() => {
  //   const user_info = accessed_user_details;
  //   console.log('SignalR_shared_with_me_Conversation => ', {state: connection?.state});

  //   if (connection?.state === 'Connected') {
  //     // console.log("SignalR_Whatsapp_Conversation: => ", {state: connection?.state, email: user_info?.email, name: user_info?.name, ticketNo: conversation?.messageId, provider: conversation?.provider });


  //     if (logged_in_user_secret_data?.wuid) {
  //         joinWorkspace(logged_in_user_secret_data?.wuid);
  //     }

  //     const handleLockWhenTyping = (details: any) => {
  //       const sender_details = JSON.parse(details);
  //       const { isFromPrevious, sender_name, ticketNo, provider, sender_email, whatsapp_replying_text } = sender_details || {};
  //       // console.log("SignalR_Whatsapp_Conversation: received data:", {isFromPrevious, sender_details: sender_details});
        
  //       if (isFromPrevious) {
  //         dispatch(toggleWhatsAppSenderState(null));
  //       } else if (sender_email !== user_info?.email && ticketNo && provider === "whatsApp") {
  //         // console.log(`SignalR_Whatsapp_Received_Conversation User with ID ${sender_name} is typing`);
  //         if(whatsapp_replying_text){
  //           dispatch(toggleWhatsAppSenderState(sender_details));
  //         } else {
  //           dispatch(toggleWhatsAppSenderState(null));
  //         }

  //         if (!whats_app_sender) {
  //           setTimeout(() => {
  //             dispatch(toggleWhatsAppSenderState(null));
  //           }, 600000); // 10 mins = 600 sec
  //         }
  //       }
  //     }

  //     // receives methods with route
  //     connection.on('TicketLocked', handleLockWhenTyping);
  //     // connection.on('TicketUnlocked', handleLockWhenTyping);

  //     // Clean up SignalR connection on component unmount
  //     return () => {
  //       connection.off('TicketLocked', handleLockWhenTyping);
  //       // connection.off('TicketUnlocked', handleLockWhenTyping);
  //       // leave workspace Id
  //       if (logged_in_user_secret_data?.wuid) {
  //         connection.invoke("LeaveWorkspace", (logged_in_user_secret_data?.wuid)).catch(err => console.error('Failed to leave workspace: ', err));
  //       }
  //     }

  //   }
  // }, [connection?.state]);

  // // invoking hook
  // useEffect(() => {
  //   const user_info = accessed_user_details;
  //   // console.log({conversation, whatsapp_replying_text, state: connection?.state});
  //   if (conversation && connection?.state === "Connected" && whatsapp_replying_text !== null) {
  //     const path = query_string.replace('?', '')?.replaceAll('&', "=");
  //     const path_array = path.split("=");
  //     const [_0, _1, _2, ticketNo, _4, _user] = path_array;
  //     const sender_info = {
  //       isFromPrevious: true,
  //       whatsapp_replying_text,
  //       whats_app_sender_state: true,
  //       sender_email: user_info?.email,
  //       sender_name: user_info?.name,
  //       ticketNo,
  //       sender_inbox_userName: conversation?.userName || _user,
  //       provider: "whatsApp"
  //     };
  //     // console.log("SignalR_Whatsapp_Conversation invoked by data: => ", {state: connection?.state, sender_info, email: user_info?.email, name: user_info?.name });
  //     if (whatsapp_replying_text?.trim()) { 
  //       sessionStorage.setItem("prev_sender_info", JSON.stringify({ whatsapp_replying_text: '', sender_email: user_info?.email, name: user_info?.name, ticketNo, provider: "whatsApp" }));
  //       sender_info.isFromPrevious = false;
  //     }

  //     // invoking methods with route
  //     const ticketId = ticketNo;
  //     const providerExample = "whatsApp";
  //     const workspaceId = logged_in_user_secret_data?.wuid;
  //     // "TicketLocked", ticketId,workspaceId,userName, provider)

  //     connection.invoke('LockTicket', true, JSON.stringify(sender_info), ticketId, providerExample, workspaceId)
  //     .catch((error: any) => {
  //       console.error('SignalR_Whatsapp_Conversation invoked: Failed to send typing status:', error);
  //     }); 
  //   }
  // }, [whatsapp_replying_text]);

  // // ===== SignalR End ====

  // if signalR updated shared with me inbox data 
  useEffect(() => {
    // console.log( "Check type ======> ",{tag_type, type, InboxData, inbox_data});
    // console.log("Embed Object ======> ", embedObject);
    // setData([]);
    // setTimeout(() => setData(InboxData || []), 10);
    /// when new signal-r date will come into shared with me inbox
    /// "is_shared_with_me_inbox_data_updated" will be true
    dispatch(toggleSharedWithMeInboxUpdated(false));
    if(InboxData?.length && nextDate){
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    
    
    const flag = nextDate;
    // console.log({flag, nextDate, InboxData});
    
    if (InboxData?.length && (nextDate === null || !flag)) {
      setHasMore(false);
    } 
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 5000);
    
    // setData(dummyData);
  }, [
    tag_type, is_shared_with_me_inbox_data_updated, // if signalR updated shared with me inbox data
  ]);

  // Ready for fetching more data by scrolling 
  useEffect(() => {
    // setTimeout(() => setData(InboxData || []), 10);
    if(InboxData?.length && nextDate){
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    const flag = nextDate;
    
    if (InboxData?.length && (nextDate === null || !flag)) {
      setHasMore(false);
    }
  }, [InboxData?.length, nextDate]);
  
  useEffect(() => {
    const path = query_string.replace('?', '')?.replaceAll('&', "=");
    const path_array = path.split("=");
    if (path_array?.length > 7) {
      const [
        _, __,
        _cov, conversation_id,
        _sn, ___,
        _ticket, ____,
      ] = path_array;
      if (InboxData?.length && conversation_id) {
        const conversation = InboxData?.find((item: any) => item?.messageId === conversation_id);
        console.log({ path_array, conversation, botInboxData: InboxData?.length });
        // console.log({conversation});
        if (!conversation) {
          navigate(`${route_path}?all=conversations`);
        }
      }
    }
  }, [InboxData?.length]);

  const fetchMoreData = () => {
    // if (nextDate === null || !nextDate?.length) {
    //   setHasMore(false);
    //   return;
    // }
    setHasMore(true);
    // a fake async api call like which sends
    console.log({type_more: true});
    
    setTimeout(() => {
      setHasMore(false);
      handleTriggerData(triggerData, {
        nextDate: moment(nextDate).format("DD MMM YYYY, hh:mm:ss A"),
        isScrolled: true,
      });
      // setData(data.concat(InboxData.slice(data?.length, data?.length + 7)));
    }, 500);
  };
  
  useEffect(() => {
    // if (!integrationConnections?.length) {
    //   const workspaceId = logged_in_user_secret_data?.wuid;
    //   dispatch(triggerGetIntegrationConnections(workspaceId));
    // }
      
    try {
      const user_info = accessed_user_details;
      if (query_string?.includes("all=conversations")
        || query_string?.includes("open=conversations")
        || query_string?.includes("closed=conversations")
        || query_string?.includes("inProgress=conversations")
        || query_string?.includes("starred=conversations")
      ) {
        setHasMore(true);
        // setTimeout(() => {
        //   setIsLoading(false);
        // }, 3000);
        const path = query_string.replace('?', '')?.replaceAll('&', "=");
        const path_array = path.split("=");
        // console.log({ path_array });
        // setActiveTab("all");
        // setActivePage("conversations");
        // console.log({ path_array });
        // console.log({ query_string });
        if (path_array?.length > 2) {
          const [active_tab, active_page] = path_array;
          setActiveTab(active_tab);
          setActivePage(active_page);
        }
        if (path_array?.length > 7) {
          const [
            _, __,
            _cov, conversation_id,
            _sn, sl_no,
            _ticket, cur_ticket_no
          ] = path_array;
          if (_cov !== "cov" && _sn !== "sn" && _ticket !== "ticket") {
            setConversation(null);
          } else {
            // console.log("See_Conversation Id ======> ", {path_array, conversation_id, sl_no, cur_ticket_no });
            // console.log("conversation", conversation);
            // let stat_details = statDetails?.find((sd: any) => sd?.userName?.toLowerCase() === user?.toLowerCase());
            // const ticketWisedData = main_item?.userWiseMessageDetailsSubs?.find((chat: any) => chat?.ticketNo === conversation_id);
            const current_conversation = InboxData?.find((item: any) => item?.messageId === conversation_id); //?.reverse();
            // const current_conversation_details = current_conversation?.ticketNoWiseDetails?.find((item: any) => item?.serialNo === sl_no);
            const current_conversation_details = current_conversation?.ticketNoWiseDetails?.filter((item: any) => item?.ticketNo === cur_ticket_no);
            const currentStatDetails = statDetails?.find((item: any) => item?.messageFromEmail === current_conversation?.messageFromEmail);
            console.log("See_Conversation Id ======> ", {statDetails, current_conversation, current_conversation_details, currentStatDetails});
            
            if (current_conversation) {
              const current_data = {
                ...current_conversation,
                userName: current_conversation?.messageFromDisplayName,
                messageFromEmail: current_conversation?.messageFromEmail,
                ticketNoWiseDetails: current_conversation_details,
                ticket_serial_no: sl_no,
                ticket_no: cur_ticket_no,
                logged_in_user_id: logged_in_user_secret_data?.cuid,
                // logged_in_user_id: user_info?.id,
                logged_in_user_email: user_info?.email,
                logged_in_user_name: user_info?.name,
                statDetails: currentStatDetails,
              };
              console.log("See_Conversation Id ======> ", { current_data });
              const team_data = {
                  teamId: current_data?.teamId,
                  clapupUserId: logged_in_user_secret_data?.cuid,
                  workspaceId: logged_in_user_secret_data?.wuid,
              };
              dispatch(getSettingTeamUserAndChannelList(team_data));
              setConversation(current_data);
              dispatch(setCurrentStateInboxConversation(current_data))
            } else {
              setConversation(null);
              dispatch(setCurrentStateInboxConversation(null));
              // navigate(route_path+`?all=conversations`);
            }
          }
          
        } else {
          // setIsLoading(true);
          // setTimeout(() => {
            //   setIsLoading(false);
            // }, 2000);
          // setStatDetails(null);
          setConversation(null);
        }
      } else {
        // setStatDetails(null);
        setConversation(null);
        // console.log({ path_array });
        // console.log({ query_string });
        if (query_string) {
          navigate(route_path+`?all=conversations`);
        }
      }
    
    } catch (err: any) {
      console.log({ err });
    }
  }, [query_string, InboxData?.length]);

  const handlePageChange = (page?: any) => {
    console.log("Page change", page);
    // if (!page) {
    //   // dispatch(toggleReplyingOn(false));
    //   return;
    // }
    if(page?.isFromReply){
      navigate(page?.value);
    } else {
      if (isReplyingOn) {
        dispatch(changePage(`${route_path}?${page}`));
      } else {
        navigate(`${route_path}?${page}`);
      }
    }
  };
  
  const left_top_tabs: any[] = [
    { title: "All", key: "all" },
    { title: "Starred", key: "starred" },
    { title: "Closed", key: "closed" },
    { title: "In Progress", key: "inProgress" },
    { title: "Open", key: "open" },
  ];

  const handleSeeOtherConversation = (item?: any) => {
    // let stat_details = statDetails?.find((sd: any) => sd?.userName === item?.userName);
    console.log({ See_Conversation_item: item });
    const route = `${route_path}?${tag_type}=${activePage}&cov=${item?.messageId}&sn=${item?.key_sl_no}&ticket=${item?.key_ticket_no}`;
    // console.log({ route });
 
    dispatch(changePage(route));
  }

  // console.log("con-length => ", {data, conversation});
  
  return (
    <React.Fragment>
      {/* {
        currentPage?.value ?
          <Dialog
            open={currentPage}
            parentCallback={(flag?: any) => handlePageChange(flag)}
            classes='dialog-container-content'
            title='Continue?'
            warning_dialog_content={"If you confirm, all your edits will be lost. Locked message will be unlocked and editor will be accessible to others."}
            footer={true}
            left_button_text='No, Cancel'
            right_button_text='Yes, Continue'
            // hover={true}
          />
        : null
        
      } */}
      <div
        className={`
          feeds-container w-100 position-relative
          ${
            data?.length && conversation
              ? "feeds-conversations-details"
              : "feeds-row-mobile"
          }
        `}
        // style={{
        //   padding: data?.length && conversationId ? "0" : "2.1% 4% 0% 2%",
        // }}
      >
        <Row className={`mobile-conversations mobile-feed`}>
          <Col
            md={3}
            className={data?.length && conversation ? "" : "feeds-section"}
          >
            {
              data?.length && conversation ? (
              <div className="conversations h-100">
                <AllMessagesConversations
                  type={type}
                  activePage={activePage}
                  activeTab={activeTab}
                  routePath={route_path}
                  conversationId={conversation?.messageId}
                  conversation={conversation}
                />
              </div>
              ) : null
            }

            {/* Search Inbox Data */}
            {!conversation ? (
              <div>
                <Search
                  parentCallback={(value: any, key: any) =>
                    filterBySearchData(value, key)
                  }
                  input_height={32}
                  is_all_messages_filter_data={false}
                  searchText={sText}
                  setSearchText={setSearchText}
                  activeTab={activeTab}
                  activePage={activePage}
                />

                <FeedMessageTabs 
                  left_top_tabs={left_top_tabs}
                  activeTab={activeTab}
                  activePage={activePage}
                  handlePageChange={handlePageChange}
                />
              </div>
            ) : null}
  
            {
              // inbox feeds
              !conversation ? (
                <div className="feeds-section-hove mt-2">
                  <InfiniteScroll
                    dataLength={data?.length || 0}
                    className="feed-scrollin"
                    scrollThreshold={0.9}
                    style={{ overflow: "scroll" }}
                    next={() => {
                      console.log(
                        `prev -> ${data?.length}, next -> ${data?.length + 20}`
                      );
                      fetchMoreData();
                    }}
                    inverse={false}
                    hasMore={hasMore}
                    loader={null
                      // !isLoading && hasMore && data?.length ? (
                      //   <div className="d-flex justify-content-center align-items-center mt-1">
                      //     <Spinner
                      //       size="sm"
                      //       animation="border"
                      //       variant="primary"
                      //     />
                      //   </div>
                      // ) : null
                    }
                    scrollableTarget="scrollableDiv"
                    // endMessage={
                    //   <p style={{ textAlign: "center" }}>
                    //     <b>Yay! You have seen it all</b>
                    //   </p>
                    // }

                    // height={"80vh"}
                    // height={"86vh"}
                    height={"76vh"}
                  >

                    {!data?.length ? (
                      <div className="empty-feeds-section">
                      {isLoading ? (
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_: any, idx: any) => (
                          <div key={idx} className="loader-section">
                            <div className="feed-loader-container">
                              <div className="loaders-container">
                                <SkeletonLoader borderRadius="50%" width="28px" height="26px" />
                                <div className="loaders-body">
                                  <div className="line-loaders">
                                    <SkeletonLoader width="190px" height="14px"/>
                                    <SkeletonLoader width="150px" height="14px"/>
                                    <SkeletonLoader width="120px" height="14px"/>
                                  </div>
                                  <SkeletonLoader borderRadius="50%" width="15px" height="15px" />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                        ) : (
                          <div className="empty-section">
                            <img src={emptyBox} alt="" />
                            <h4>Empty Feeds</h4>
                          </div>
                        )}
                      </div>
                    ) : (
                      data?.map((item: any, index: number) => {
                        return (
                          <div key={index}>
                            <MessagesInboxCard
                              type={type}
                              lastEmailDetails={lastEmailDetails}
                              handleSeeOtherConversation={handleSeeOtherConversation}
                              inbox={item}
                              activePage={activePage}
                              activeTab={activeTab}
                              routePath={route_path}
                              // wallData={wallData}
                              // setWallData={setWallData}
                            />
                          </div>
                        );
                      })
                    )}

                  </InfiniteScroll>
                </div>
              ) : null
            }
          </Col>
        </Row>
      </div>

      <div className="feeds-row-lg feeds-container">
        <div
          style={{
            // width: `${query_string?.includes("conversations") ? "99%" : "98%"}`,
            // height: "92vh",
            overflow: "hidden",
            // display: "flex",
            // gap: '0.4%',
          }}
          className="feed-row-lg-container"
        >
          <div className="conversation-left-side">
            <FeedLeftBar/>
          </div>
          <div className="feeds-sections">
            <FeedMessageTabs 
              left_top_tabs={left_top_tabs}
              activeTab={activeTab}
              activePage={activePage}
              handlePageChange={handlePageChange}
            />
            
            <div className="feeds-section-hover">
              <InfiniteScroll
                dataLength={data?.length || 0}
                // className="scrollin"
                scrollThreshold={0.9}
                style={{ overflow: "scroll", paddingRight: 2 }}
                next={() => {
                  console.log(
                    `prev -> ${data?.length}, next -> ${data?.length + 20}`
                  );
                  fetchMoreData();
                }}
                inverse={false}
                hasMore={hasMore}
                loader={null
                  // !isLoading && hasMore && data?.length ? (
                  //   <div className="d-flex justify-content-center align-items-center mt-1">
                  //     <Spinner
                  //       size="sm"
                  //       animation="border"
                  //       variant="primary"
                  //     />
                  //   </div>
                  // ) : null
                }
                scrollableTarget="scrollableDiv"
                // endMessage={
                //   <p style={{ textAlign: "center" }}>
                //     <b>Yay! You have seen it all</b>
                //   </p>
                // }
                // height={"85vh"}
                height={"88vh"}
              >
                <div className="search-bar-container">
                  <Search
                    parentCallback={(value: any, key: any) =>
                      filterBySearchData(value, key)
                    }
                    input_height={32}
                    is_all_messages_filter_data={false}
                    searchText={sText}
                    setSearchText={setSearchText}
                    activeTab={activeTab}
                    activePage={activePage}
                  />
                </div>

                {/* Infinity Scrolling */}

                {isLoading ? (
                  <div className="empty-feeds-section">
                  {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_: any, idx: any) => (
                      <div key={idx} className="loader-section">
                        <div className="feed-loader-container">
                          <div className="loaders-container">
                            <SkeletonLoader borderRadius="50%" width="28px" height="26px" />
                            <div className="loaders-body">
                              <div className="line-loaders">
                                <SkeletonLoader width="190px" height="14px"/>
                                <SkeletonLoader width="150px" height="14px"/>
                                <SkeletonLoader width="120px" height="14px"/>
                              </div>
                              <SkeletonLoader borderRadius="50%" width="15px" height="15px" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))

                  }
                  </div>
                ) : !data?.length && (empty_data || isEmpty) ? (
                  <div className="empty-feeds-section">
                    <div className="empty-section">
                      <img src={emptyBox} alt="" />
                      <h4>Empty Feeds</h4>
                    </div>
                  </div>
                ) : (
                  data?.map((item: any, index: number) => {
                    return (
                      <div key={index}>
                        <MessagesInboxCard
                          type={type}
                          inbox={item}
                          lastEmailDetails={lastEmailDetails}
                          handleSeeOtherConversation={handleSeeOtherConversation}
                          activePage={activePage}
                          activeTab={activeTab}
                          routePath={route_path}
                          // wallData={wallData}
                          // setWallData={setWallData}
                        />
                      </div>
                    );
                  })
                )}
              </InfiniteScroll>
            </div>
          </div>
          <div className={"conversation-sections"}>
            {(data?.length && conversation) || query_string?.includes("cov") ? (
              <div className="conversations h-100">
                <AllMessagesConversations
                  type={type}
                  activePage={activePage}
                  activeTab={activeTab}
                  routePath={route_path}
                  conversationId={conversation?.messageId}
                  conversation={conversation}
                />
              </div>
            ) : (
              <div className="empty-wall-section">
                <img src={emptyBox} alt="" />
                {/* <h4>Your Wall is Empty.</h4> */}
                <p>Select an item from inbox to display!</p>
              </div>
            )}
          </div>
          {
            (data?.length && conversation) || query_string?.includes("cov") ? (
              <div className="conversation-details-right-side">
                <div className="whats-app-details-container">
                  <MessagesDetails type={type} statDetails={conversation} />
                </div>
              </div>
            ) : null
          }
        </div>
      </div>
    </React.Fragment>
  );
}

export default SharedWithMeMessageFeeds;
