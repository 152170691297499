import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation, useParams } from "react-router-dom";
// import Cookies from 'js-cookie';
import {
    getKeyValue, getAllKeysFromAllStores,
    deleteDatabase,
    // deleteKeyValue, clearStoredAttachmentFileData,
} from './utils/indexedDBManagementService';
import { setLoggedInUserSecretData } from './signup-login/loginReducer';
import Constants from './constants';
import { setLogout } from './components/Layout/header/headerReducer';
const SESSION_TIMEOUT = 30 * 60 * 1000; // n x 60 x 1000 = n minutes in milliseconds

// import { triggerLogout } from './signup-login/loginReducer';
const PrivateRoute = ({ children }: any) => {
    const [isLoggedIn, setIsLoggedIn] = useState<any>(false);
    const [isLoading, setIsLoading] = useState<any>(true);
    const dispatch = useDispatch();
    const location = useLocation();
    const { params } = useParams();
    const {
        logged_in_user_secret_data,
        // accessed_user_details, integrated_user_details
     } = useSelector((state: any) => state?.login);
    
    // Close session
    useEffect(() => { 
        (async () => {
            try {
                const handleBeforeUnload = async() => {
                    // This event triggers when the tab or window is about to close
                    // You can put any logic you want to execute here, for example:
                    // Optional: Show a confirmation dialog before the tab is closed
                    try {
                        await deleteDatabase();
                        dispatch({ type: Constants.LOGOUT });
                        dispatch({ type: "LOGOUT" });
                        dispatch(setLogout(true));
                        setIsLoggedIn(false);
                    } catch (err: any) {
                        console.log({ err });
                    }
                };
                const sessionStartTime: any = localStorage.getItem('session_start_time');
                const currentTime = Date.now();
                const index_session = localStorage.getItem("index_session");
                // console.log('Session is being closed', { index_session });

                setIsLoading(true);
                if (sessionStartTime && currentTime - parseInt(sessionStartTime, 10) >= SESSION_TIMEOUT) {
                    console.log('Session expired, clearing data...');
                    handleBeforeUnload();
                }
                else if (index_session !== "true") {
                    // // content management
                    // await deleteKeyValue("folder_paths");
                    // await deleteKeyValue("all_path_contents");
                    // await deleteKeyValue("all_child_folders");
                    // // team inbox
                    // await deleteKeyValue("all_team_inbox_shared_users");
                    // await deleteKeyValue("isReplying");
                    // await clearStoredAttachmentFileData();
                    handleBeforeUnload();
                } else if (!logged_in_user_secret_data) {
                    const user_secret_data: any = await getKeyValue("logged_in_user_secret_data") || {};
                    const user_details_data: any = await getKeyValue("accessed_user_details") || {};
                    // const integrated_user_details = await getKeyValue("integrated_user_details");
                    // const all_keys_store_data = await getAllKeysFromAllStores();
                    console.log({
                        // user_secret_data,
                        user_details_data,
                        // all_keys_store_data
                    });
                    if (!user_secret_data) {
                        dispatch({ type: "LOGOUT" });
                        return;
                    }
                    if (user_secret_data) {
                        setIsLoggedIn(!!user_secret_data);
                        dispatch(setLoggedInUserSecretData({
                            accessed_user_details: user_details_data,
                            // logged_in_user_secret_data: {...user_secret_data, roleName: "Viewer"},
                            logged_in_user_secret_data: user_secret_data || null,
                        }));
                    } else {
                        dispatch({ type: "LOGOUT" });
                    }
                } else {
                    setIsLoggedIn(true);
                }
            } catch (error) {
                console.error('Error fetching user secret data:', error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [logged_in_user_secret_data]);

    // Extend session on user activity
    useEffect(() => {
        const updateSessionTime = () => {
            const currentTime = Date.now();
            // console.log({ currentTime });
            
            localStorage.setItem("session_start_time", currentTime.toString());
        };

        window.addEventListener("click", updateSessionTime);
        window.addEventListener("keypress", updateSessionTime);

        return () => {
            window.removeEventListener("click", updateSessionTime);
            window.removeEventListener("keypress", updateSessionTime);
        };
    }, []);
    
    // if route changed
    useEffect(() => {
        const updateSessionTime = () => {
            const currentTime = Date.now();
            // console.log({ currentTime, params, pathname: location.pathname, search_name: location.search });
            localStorage.setItem("index_session", "true");
            localStorage.setItem("session_start_time", currentTime.toString());
        };

        updateSessionTime();
    }, [location.pathname, location.search, params]);

    // sessionStorage.setItem('roleName', "Super Admin");
    // sessionStorage.setItem('cuid', "1edcb220-3cfa-45f5-8320-d7a3d64868e4");
    // sessionStorage.setItem('roleId', "2d88e4c1-fd0c-11ec-b7d3-e4e74965c2c3");
    // sessionStorage.setItem('wuid', "766a0d41-fd19-11ec-b7d3-e4e74965c2c3");

    // console.log("sessionAuth ===============> ", sessionAuth);
    // console.log("sessionStorage.getItem('cuid') ===============> ", sessionStorage.getItem("cuid"));
    // console.log("sessionStorage.getItem('roleId') ===============> ", sessionStorage.getItem("roleId"));
    // console.log("sessionStorage.getItem('wuid') ===============> ", sessionStorage.getItem("wuid"));
    // ==== we have to track ====
    // all session storage items show
    // const sessionData = sessionStorage;
    // for(let i = 0; i < sessionData?.length; i++) {
    //     const key: any = sessionData.key(i);
    //     const value: any = sessionData.getItem(key);
    //     const flag = localStorage.getItem(key) !== value &&
    //     key !== undefined && key !== "message_type"
    //     // console.log(key, "=== key ==> ", value);
    //     if (flag) {
    //         localStorage.setItem(key, value);
    //     }
    // }
    
    // const localStorageData = localStorage;
    // for (let i = 0; i < localStorageData?.length; i++) {
    //     const key: any = localStorageData.key(i);
    //     const value: any = localStorageData.getItem(key);
    //     // console.log(key, "=== key ==> ", value);
    //     const flag = sessionStorage.getItem(key) !== value &&
    //         key !== undefined && key !== "access_token" &&
    //         key !== "refresh_token"
    //     if (flag) {
    //         sessionStorage.setItem(key, value);
    //     }
    // }

    return isLoggedIn ? children 
        : !isLoading && !isLoggedIn ? <Navigate to="/login" state={{ from: location.pathname }} />
        : null
}

export default PrivateRoute;
